import { makeAutoObservable } from "mobx";
import { ENV } from "../config";
import { request } from "../utils";

class CoursesStore {
  constructor() {
    makeAutoObservable(this);
    this.fetchCourses();
  }

  loading = false;

  rawCourses = [];

  get courses() {
    return this.rawCourses.slice()?.map(c => {
      return { ...c, courseLogo: `https://${ENV}-lpm-assets.b-cdn.net/icons/${c?.id}?m=${c?.modified}` };
    });
  }

  get coursesById() {
    return Object.fromEntries(this.courses?.map(course => [course?.id, course]));
  }

  async fetchCourses() {
    this.loading = true;
    try {
      const courses = await request.unauthenticated.get(`/courses`);
      this.rawCourses = this.rawCourses.concat(courses);
      this.loading = false;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  clear() {
    this.rawCourses = null;
  }
}

export default new CoursesStore();
