import React, { useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { EMAIL_REGEX } from "../../../constants";
import useOnEnterPressed from "../../../hooks/useOnEnterPressed";
import AuthStore from "../../../stores/AuthStore";
import { Input, Spinner, Button } from "../../../components/-common";
import "./ForgotPassword.scss";

const ForgotPassword = observer(() => {
  const [email, setEmail] = useState("");
  const updateEmail = ({ target }) => {
    setError();
    setEmail(target.value);
  };

  const [error, setError] = useState();

  const sendCodeEnabled = email?.match(EMAIL_REGEX);
  const sendVerificationCode = async () => {
    if (sendCodeEnabled) {
      setError();
      const { success, error: errorFromStore } = await AuthStore?.sendResetPasswordCode(email);
      if (success) navigate("/auth/resetpassword/finish");
      else if (errorFromStore) setError(errorFromStore);
    }
  };
  useOnEnterPressed(sendVerificationCode);

  const backToLogIn = () => navigate("/auth");

  const buttonContents = AuthStore.loading ? <Spinner /> : "Send Verification Code";
  const buttonStyles = AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};

  return (
    <div className="forgot-password">
      <div className="left-col">
        <h1 className="hero-text">Forgot your password?</h1>
        <p className="helper-text">
          No worries - we'll get you taken care of. Enter the email you created the account with and we'll send a
          verification code.
        </p>
      </div>
      <div className="right-col">
        <div className="auth-content">
          <div className="auth-square">
            <div className="auth-inputs">
              <Input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={updateEmail}
                error={error}
                autoComplete="email"
              />
              <Button
                type="cta"
                className="send-code-button"
                style={buttonStyles}
                action={sendVerificationCode}
                disabled={!sendCodeEnabled}
              >
                {buttonContents}
              </Button>
            </div>
            <div className="no-account">
              <span>Suddenly remembered it?</span>
              <Button type="cta" className="sign-up-button" action={backToLogIn}>
                Back to Log In
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ForgotPassword;
