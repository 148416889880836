import { request } from "../utils";
import browserInfo from "browser-info";
import AuthStore from "../stores/AuthStore";
import UserStore from "../stores/UserStore";

async function logError({ error }) {
  try {
    const { fullName, parent, teacher, contactType } = UserStore?.user || {};
    await request.post("/log", {
      body: {
        error: {
          message: error?.message,
          stack: error?.stack
        },
        metadata: {
          source: "Enrollment",
          browser: browserInfo(),
          auth: { sub: AuthStore.sub, lpmId: AuthStore.id, infusionsoftId: AuthStore.infusionsoftId },
          user: { fullName, parent, teacher, contactType }
        }
      }
    });
    return true;
  } catch (err) {
    return false;
  }
}

export default logError;
