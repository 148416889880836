import { makeAutoObservable } from "mobx";

class ClassMapStore {
  constructor() {
    makeAutoObservable(this);
  }

  currentCenter = null;
  currentZoom = 12;

  setCurrentCenter(center) {
    this.currentCenter = center;
  }

  setCurrentZoom(zoom) {
    this.currentZoom = zoom;
  }
}

export default new ClassMapStore();
