import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import RSelect from "react-select";
import { FiBox, FiMinus, FiPlus } from "react-icons/fi";
import { ENV } from "../../config";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import MaterialsStore from "../../stores/MaterialsStore";
import AuthStore from "../../stores/AuthStore";
import CartStore from "../../stores/CartStore";
import UserStore from "../../stores/UserStore";
import { Button, Spinner } from "../-common";
import { selectStyles } from "../-common/Select/Select";
import "./SelectMaterials.scss";

const BundleItem = observer(({ selectProps, data: bundle, innerProps }) => {
  const { value: selectedBundle } = selectProps || {};
  const { id, name, productNames, price } = bundle || {};

  const [imageError, setImageError] = useState(false);
  const markImageInvalid = () => setImageError(true);

  const selected = selectedBundle?.id === id;

  const image = imageError ? (
    <div className="preview-image fallback">
      <FiBox color="var(--medium-gray)" size={40} />
    </div>
  ) : (
    <img
      className="preview-image"
      src={`https://${ENV}-lpm-assets.b-cdn.net/products/${id}`}
      alt="Products in this bundle."
      onError={markImageInvalid}
    />
  );

  return (
    <div className={`bundle-item-wrapper${selected ? " selected" : ""}`} {...innerProps}>
      <div className="bundle-item-content">
        {image}
        <div className="info-container">
          <div className="bundle-item-title">{name}</div>
          <div className="bundle-item-info">{productNames}</div>
          <div style={{ flex: 1 }} />
          <div className="bundle-item-price">${(price / 100).toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
});

const ProductItem = ({ product, updateMaterialQuantityForEnrollmentItem }) => {
  const { isMobile } = useBreakpoints();

  const { id, name, longDescription: description, price } = product || {};
  const productInCart = CartStore?.activeEnrollmentItem?.products?.find(p => p?.id === id);
  const { quantity } = productInCart || {};

  const [imageError, setImageError] = useState(false);
  const markImageInvalid = () => setImageError(true);

  const removeQuantity = () => {
    updateMaterialQuantityForEnrollmentItem(product, Math.max(0, (quantity || 0) - 1));
  };

  const addQuantity = () => {
    updateMaterialQuantityForEnrollmentItem(product, (quantity || 0) + 1);
  };

  const image = imageError ? (
    <div className="preview-image fallback">
      <FiBox color="var(--medium-gray)" size={40} />
    </div>
  ) : (
    <img
      className="preview-image"
      src={`https://${ENV}-lpm-assets.b-cdn.net/products/${id}`}
      alt="What this product looks like."
      onError={markImageInvalid}
    />
  );

  const priceLabel = <div className="material-item-price">${(price / 100).toFixed(2)}</div>;

  const itemInfoSection = isMobile ? (
    <div className="item-info">
      {image}
      <div className="info-container">
        <div className="material-item-title">{name}</div>
        <div className="material-item-info">{description}</div>
        <div style={{ flex: 1 }} />
      </div>
    </div>
  ) : (
    <>
      {image}
      <div className="info-container">
        <div className="material-item-title">{name}</div>
        <div className="material-item-info">{description}</div>
        <div style={{ flex: 1 }} />
        {priceLabel}
      </div>
    </>
  );

  const quantityContainer = (
    <div className="quantity-container">
      <FiMinus className={`quantity-icon${!quantity ? " disabled" : ""}`} strokeWidth={4} onClick={removeQuantity} />
      <div className="quantity">{quantity || 0}</div>
      <FiPlus className="quantity-icon" strokeWidth={4} onClick={addQuantity} />
    </div>
  );
  const quantitySection = isMobile ? (
    <div className="bottom-row">
      {priceLabel}
      {quantityContainer}
    </div>
  ) : (
    quantityContainer
  );

  return (
    <div className="material-item-wrapper">
      <div className="material-item-content">
        {itemInfoSection}
        {quantitySection}
      </div>
    </div>
  );
};

const makeSelectedBundleLabel = ({ name, productList }) => {
  const price = productList?.map(p => p?.price)?.reduce((acc, next) => acc + next, 0);
  return name + " - $" + (price / 100)?.toFixed(2);
};

const StudentBundleLine = ({ student, index, bundles, selectBundleForStudent, defaultToSibling }) => {
  const selectedBundle = CartStore?.activeEnrollmentItem?.bundles?.[student?.id];

  useEffect(() => {
    if (!selectedBundle) {
      if (index === 0 && !defaultToSibling) selectBundleForStudent(bundles?.[0]);
      else selectBundleForStudent(bundles?.[1]);
    }
  }, [selectedBundle, index, defaultToSibling, selectBundleForStudent, bundles]);

  return (
    <div className="student-line-wrapper">
      <div className="student-name-label">{student?.firstName}: </div>
      <RSelect
        styles={selectStyles}
        student={student}
        components={{ Option: BundleItem }}
        options={bundles}
        value={selectedBundle}
        onChange={selectBundleForStudent}
        placeholder="Select bundle..."
        formatOptionLabel={makeSelectedBundleLabel}
      />
    </div>
  );
};

const SelectMaterials = observer(() => {
  const { isMobile } = useBreakpoints();

  const { authenticated } = AuthStore || {};
  const enrollmentHasStudents = !!CartStore?.activeEnrollmentItem?.students?.length;
  useEffect(() => {
    if (!authenticated || !enrollmentHasStudents) navigate("/");
  }, [authenticated, enrollmentHasStudents]);

  const { class: selectedClass, students } = CartStore?.activeEnrollmentItem || {};
  const { courseId, course } = selectedClass || {};

  const { loading, materialsByCourseId } = MaterialsStore || {};
  const materials = materialsByCourseId?.[courseId];

  const hasHadThisCourseBefore = !!UserStore?.coursesToDate?.find(c => c?.id === courseId);

  useEffect(() => {
    if (courseId) MaterialsStore.fetchMaterialsForCourse(courseId);
  }, [courseId]);

  const selectBundle = student => bundle => {
    CartStore?.updateEnrollmentInCart(CartStore?.activeEnrollmentId, {
      bundles: {
        ...CartStore?.activeEnrollmentItem?.bundles,
        [student?.id]: bundle
      }
    });
  };

  const updateMaterialQuantityForEnrollmentItem = (product, quantity) => {
    let currentProducts = CartStore?.activeEnrollmentItem?.products || [];

    let updatedProducts;
    if (currentProducts?.find(p => p?.id === product?.id)) {
      updatedProducts = currentProducts?.map(p => {
        if (p?.id === product?.id) return { ...product, quantity };
        return p;
      });
    } else {
      updatedProducts = currentProducts?.concat({ ...product, quantity });
    }

    CartStore?.updateEnrollmentInCart(CartStore?.activeEnrollmentId, { products: updatedProducts });
  };

  const enrollAnotherStudent = () => navigate("/");

  const goToCheckout = () => navigate("/checkout");

  const studentBundleLines = students?.map((s, i) => (
    <StudentBundleLine
      student={s}
      index={i}
      bundles={materials?.bundles}
      selectBundleForStudent={selectBundle(s)}
      defaultToSibling={hasHadThisCourseBefore}
      key={s?.id}
    />
  ));

  const productItems = materials?.products
    ?.filter(product => !product?.name?.toLowerCase().includes("tax"))
    ?.map(product => <ProductItem {...{ product, updateMaterialQuantityForEnrollmentItem }} key={product?.id} />);

  const selectMaterialsContents = loading ? (
    <div className="loading-materials">
      <Spinner alt />
    </div>
  ) : (
    <div className="list-container">
      <div className="list-type-title">Select a Bundle for Each Student</div>
      {studentBundleLines}
      <div className="list-type-title">Add Extras</div>
      {productItems}
    </div>
  );

  const [courseLogoError, setCourseLogoError] = useState(false);
  const markCourseLogoError = () => setCourseLogoError(false);
  const courseLogo = courseLogoError ? null : (
    <img className="course-logo" src={course?.courseLogo} alt="Course logo" onError={markCourseLogoError} />
  );

  const suggestionLabelPrefix = hasHadThisCourseBefore ? "you've had a student in" : "you're new to";
  const courseLabel = course?.name || "this course";

  let suggestionLabelPostfix = "";
  if (hasHadThisCourseBefore) {
    if (students?.length > 1) {
      suggestionLabelPostfix =
        "before - we've suggested all sibling bundles assuming you've still got the kit from your previous child's class. If not, select the full bundle.";
    } else {
      suggestionLabelPostfix =
        "before - we've suggested the sibling bundle assuming you've still got the kit from your previous child's class. If not, select the full bundle.";
    }
  } else {
    if (students?.length > 1) {
      suggestionLabelPostfix =
        "- we've suggested one full bundle and the rest sibling bundles to make sure you've got a full kit, but no more than you need. If you've already got a full kit somewhere, select all sibling bundles.";
    } else {
      suggestionLabelPostfix =
        "- we've suggested the full bundle to make sure you've got everything you need. If you've already got a full kit somewhere, select the sibling bundle.";
    }
  }

  const suggestionLabel = `${suggestionLabelPrefix} ${courseLabel} ${suggestionLabelPostfix}`;

  const firstContentSlot = isMobile ? null : <div className="left-col">{selectMaterialsContents}</div>;
  const secondContentSlot = isMobile ? <div className="left-col">{selectMaterialsContents}</div> : null;
  const buttonsRow = (
    <div className="buttons-row">
      <div className="cta-button-wrapper">
        <Button type="cta" action={enrollAnotherStudent}>
          Enroll More Children
        </Button>
        <Button type="cta" action={goToCheckout}>
          Go to Checkout
        </Button>
      </div>
    </div>
  );

  return (
    <div className="select-materials-wrapper">
      {firstContentSlot}
      <div className="right-col">
        <h1 className="hero-text">Let's get your materials reserved and ready.</h1>
        {courseLogo}
        <p className="helper-text">It looks like {suggestionLabel}</p>
        <p className="helper-text">Add any extras you'd like, and then we're on to the last step!</p>
        {isMobile ? null : buttonsRow}
      </div>
      {secondContentSlot}
      {isMobile ? buttonsRow : null}
    </div>
  );
});

export default SelectMaterials;
