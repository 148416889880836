import { useLocation } from "@reach/router";
import qs from "qs";

const useQueryParams = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });
  return queryParams;
};

export default useQueryParams;
