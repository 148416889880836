import React, { useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import ClassesStore from "../../../stores/ClassesStore";
import CartStore from "../../../stores/CartStore";
import AuthStore from "../../../stores/AuthStore";
import { Input, Spinner, TeacherListing } from "../../-common";
import "./ListSearch.scss";
import { FiSearch } from "react-icons/fi";

const ListSearch = observer(({ openTeacherBio, openTeacherTestimonials, openSampleClassForTeacher }) => {
  const { loading: loadingSearchResults, searchResults } = ClassesStore || {};

  const [searchText, setSearchText] = useState("");
  const updateSearchText = async ({ target }) => {
    setSearchText(target.value);
    ClassesStore.searchByName(target.value);
  };

  const startEnrollmentInClass = (teacher, selectedClass) => {
    CartStore.addEnrollmentToCart({ teacher, class: selectedClass });
    if (!AuthStore?.authenticated) navigate("/auth");
    else navigate("/students");
  };

  const zeroStateMessage = !!searchText
    ? "No results found... try altering your search a bit."
    : "Type something above to start searching!";
  const teacherItems = searchResults?.length ? (
    searchResults.map(teacher => (
      <TeacherListing
        {...{
          teacher,
          openBio: openTeacherBio,
          openTestimonials: openTeacherTestimonials,
          openSampleClass: openSampleClassForTeacher,
          startEnrollmentInClass
        }}
        key={teacher?.id}
      />
    ))
  ) : (
    <div className="zero-state-wrapper">
      <div className="zero-state-text">{zeroStateMessage}</div>
    </div>
  );
  const results = loadingSearchResults ? (
    <div className="loading-wrapper">
      <Spinner alt />
    </div>
  ) : (
    teacherItems
  );

  return (
    <div className="list-search-wrapper">
      <div className="left-col">
        <h1 className="hero-text">Let's find your teacher!</h1>
        <p className="helper-text">
          Sounds like you know who you're looking for. Use the search bar to find your teacher, then pick from the
          classes they're offering this semester.
        </p>
      </div>
      <div className={`right-col list`}>
        <div className="search-input-container">
          <Input
            icon={FiSearch}
            placeholder="Search teachers by name"
            value={searchText}
            onChange={updateSearchText}
            autoComplete="off"
          />
        </div>
        <div className="list-container">{results}</div>
      </div>
    </div>
  );
});

export default ListSearch;
