import React from "react";
import { navigate } from "@reach/router";
import groupBy from "lodash.groupby";
import numeral from "numeral";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import CartStore from "../../../stores/CartStore";
import { Button } from "../../-common";
import "./EnrollmentItem.scss";

const EnrollmentItem = ({ id, teacher, class: classItem, students, bundles, products, total, priceDiscount }) => {
  const { isMobile } = useBreakpoints();

  const { name: courseName } = classItem?.course || {};

  const [lastStudent, ...restOfStudents] = students?.slice()?.reverse();
  const firstStudentNames = restOfStudents
    ?.map(s => s?.firstName)
    ?.reverse()
    ?.join(", ");
  const lastStudentLabel =
    (!!restOfStudents?.length ? `${students?.length > 2 ? "," : ""} and ` : "") + lastStudent?.firstName;
  const studentNames = firstStudentNames + lastStudentLabel;

  const { firstName: teacherFirstName, lastName: teacherLastName } = teacher || {};

  const updateMaterials = () => {
    CartStore?.setActiveEnrollmentId(id);
    navigate("/materials");
  };

  const removeEnrollment = () => CartStore?.removeEnrollmentFromCart(id);

  const teacherName = `${teacherFirstName} ${teacherLastName}`;

  const studentNameLabel = studentNames ? `for ${studentNames}` : "(no child selected yet)";

  let totalAfterDiscount = numeral(CartStore?.productsTotal / 100).format("$0,00.00");

  const totalLabel = total ? (
    <div className="total">
      {isMobile ? "TOTAL: " : ""}
      <span style={{ textDecoration: priceDiscount ? "line-through" : "none", opacity: priceDiscount ? 0.5 : 1 }}>
        {numeral(total / 100).format("$0,00.00")}
      </span>{" "}
      {priceDiscount ? totalAfterDiscount : ""}
    </div>
  ) : null;

  const groupedBundles = groupBy(Object.values(bundles), "id");

  const bundleLines = Object.values(groupedBundles)
    ?.map((bundleGroup, i) => {
      const quantity = bundleGroup?.length;
      const { name, price } = bundleGroup?.[0];
      if (!price || price <= 0) return false;

      const label = quantity + "x " + name + " - ";
      const totalLabel = numeral((price * quantity) / 100).format("$0,0.00");
      const totalAfterDiscountLabel = bundleGroup?.[0]?.totalAfterDiscount
        ? " " + numeral(bundleGroup?.[0]?.totalAfterDiscount / 100).format("$0,0.00")
        : "";

      return (
        <div className="bundle-line" key={i}>
          {label}
          <span
            style={{
              textDecoration: totalAfterDiscountLabel ? "line-through" : "none",
              opacity: totalAfterDiscountLabel ? 0.5 : 1
            }}
          >
            {totalLabel}
          </span>
          {totalAfterDiscountLabel}
        </div>
      );
    })
    ?.filter(Boolean);

  const productLines = products
    ?.filter(p => p?.quantity > 0)
    ?.map(({ name, price, quantity, totalAfterDiscount }) => {
      const label = quantity + "x " + name + " - ";
      const totalLabel = numeral((price * quantity) / 100).format("$0,0.00");
      const totalAfterDiscountLabel = totalAfterDiscount
        ? " " + numeral(totalAfterDiscount / 100).format("$0,0.00")
        : "";

      return (
        <div className="product-line" key={name}>
          {label}
          <span
            style={{
              textDecoration: totalAfterDiscountLabel ? "line-through" : "none",
              opacity: totalAfterDiscountLabel ? 0.5 : 1
            }}
          >
            {totalLabel}
          </span>
          {totalAfterDiscountLabel}
        </div>
      );
    });

  const hasStudents = !!students?.length;
  const hasBundles = !!Object.values(bundles || {})?.length;

  const updateMaterialsButton =
    hasStudents && hasBundles ? (
      <Button type="cta" action={updateMaterials} className="update-materials-button">
        Update Materials
      </Button>
    ) : null;

  return (
    <div className="checkout-enrollment-item">
      <div className="enrollment-item-title">
        {courseName} with {teacherName}
      </div>
      <div className="student-name">{studentNameLabel}</div>
      {bundleLines?.length ? bundleLines : <div className="no-materials">(no materials)</div>}
      {productLines}
      <div className="bottom-row">
        <span>{totalLabel}</span>

        <div className="buttons">
          {updateMaterialsButton}
          <Button type="negative" action={removeEnrollment}>
            Remove
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EnrollmentItem;
