import React from "react";
import "./AccountError.scss";

function AccountError() {
  return (
    <div className="account-error-page">
      <div className="title">Oops, looks like something's not right here.</div>
      <div className="message">
        It's likely just a little data that's out of sync. Reach out to us at{" "}
        <a href="mailto:office@letsplaymusicsite.com">office@letsplaymusicsite.com</a> and we'll get you taken care of.
      </div>
    </div>
  );
}

export default AccountError;
