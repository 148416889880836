import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { FiPlus, FiUser } from "react-icons/fi";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import useModal from "../../hooks/useModal";
import AuthStore from "../../stores/AuthStore";
import UserStore from "../../stores/UserStore";
import CartStore from "../../stores/CartStore";
import { Spinner, Button } from "../-common";
import AddStudentModalBody from "./AddStudentModal";
import "./SelectStudents.scss";

const ChildItem = ({ child, selected, toggleChildSelected, selectEnabled }) => {
  const { id, firstName, lastName, birthdayText, gender } = child || {};

  let genderColor = "var(--medium-gray)";
  if (gender === "female") genderColor = "var(--female-pink)";
  if (gender === "male") genderColor = "var(--male-blue)";

  const toggleChild = () => toggleChildSelected(id);

  return (
    <div
      className={`child-item-wrapper${selected ? " selected" : ""}${!selectEnabled ? " disabled" : ""}`}
      onClick={toggleChild}
    >
      <div className="child-item-content">
        <div className={`profile-image ${gender ?? ""}`}>
          <FiUser color={genderColor} size={40} />
        </div>
        <div className="info-container">
          <div className="child-item-name">
            {firstName} {lastName}
          </div>
          <div className="child-item-birthday">{birthdayText}</div>
        </div>
      </div>
    </div>
  );
};

const SelectStudents = observer(({ openAddStudentModal }) => {
  const { isMobile } = useBreakpoints();

  const { authenticated } = AuthStore || {};
  const { id: teacherIdToWatch } = CartStore?.activeEnrollmentItem?.teacher || {};
  const { id: classId, classId: fallbackClassId, seatsAvailable } = CartStore?.activeEnrollmentItem?.class || {};
  const classIdToWatch = classId || fallbackClassId;
  useEffect(() => {
    if (!authenticated || !teacherIdToWatch || !classIdToWatch) navigate("/");
  }, [authenticated, teacherIdToWatch, classIdToWatch]);

  const { loadingChildren, children } = UserStore || {};

  const [selectedChildren, setSelectedChildren] = useState([]);

  const selectEnabled = selectedChildren?.length < seatsAvailable;
  const selectChild = childId => {
    if (selectEnabled) {
      setSelectedChildren(Array.from(new Set(selectedChildren.concat(childId))));
    }
  };
  const unselectChild = childId => setSelectedChildren(selectedChildren.filter(id => id !== childId));
  const toggleChildSelected = childId => {
    if (selectedChildren?.includes(childId)) unselectChild(childId);
    else selectChild(childId);
  };

  const continueEnabled = !!selectedChildren?.length;
  const addStudentsToActiveEnrollment = () => {
    if (continueEnabled) {
      const students = selectedChildren?.map(childId => children?.find(c => c?.id === childId))?.filter(Boolean);
      CartStore.updateEnrollmentInCart(CartStore?.activeEnrollmentId, { students });
      navigate("/materials");
    }
  };

  const { teacher, class: currentClass } = CartStore?.activeEnrollmentItem || {};
  const { firstName: teacherFirstName } = teacher || {};
  const { sessionTime, course } = currentClass || {};
  const { name: courseName } = course || {};
  const classLabel = `${teacherFirstName}'s ${sessionTime}${courseName ? " " + courseName : ""} class`;

  const childItems = children.map(child => {
    const selected = selectedChildren?.includes(child?.id);
    return <ChildItem {...{ child, selected, toggleChildSelected, selectEnabled }} key={child.id} />;
  });

  const topAddChildButton = isMobile ? null : (
    <div className="add-child-row">
      <div className="add-child" onClick={openAddStudentModal}>
        <FiPlus strokeWidth="3" />
        <span>Add A New Child</span>
      </div>
    </div>
  );
  const bottomAddChildButton = isMobile ? (
    <div className="add-child-row">
      <div className="add-child" onClick={openAddStudentModal}>
        <FiPlus strokeWidth="3" />
        <span>Add A New Child</span>
      </div>
    </div>
  ) : null;

  const moreChildrenToSelect = children?.length !== selectedChildren?.length;
  const noMoreSeatsMessage =
    !selectEnabled && moreChildrenToSelect ? (
      <div className="no-more-seats">
        That's all the available seats - if you've got more children to enroll, you'll need to get them in a different
        class.
      </div>
    ) : null;

  const selectChildrenContents = loadingChildren ? (
    <div className="loading-children">
      <Spinner alt />
    </div>
  ) : (
    <>
      {topAddChildButton}
      <div className="list-container">{childItems}</div>
      {bottomAddChildButton}
      {noMoreSeatsMessage}
      <div className="buttons-row">
        <div className="cta-button-wrapper">
          <Button type="cta" action={addStudentsToActiveEnrollment} disabled={!continueEnabled}>
            Continue to Materials
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <div className="select-students-wrapper">
      <div className="left-col">
        <h1 className="hero-text">Which children would you like to enroll?</h1>
        <p className="helper-text">
          Don't worry - if you have more children that you want to put in other classes, we'll come back for them. For
          now, who would you like to sign up for {classLabel}?
        </p>
      </div>
      <div className="right-col">{selectChildrenContents}</div>
    </div>
  );
});

const SelectStudentsWithModal = () => {
  const { Modal: AddStudentModal, openModal: openAddStudentModal } = useModal(
    AddStudentModalBody,
    {},
    { portalElementId: "add-student-modal" }
  );

  return (
    <>
      <SelectStudents openAddStudentModal={openAddStudentModal} />
      <AddStudentModal />
    </>
  );
};

export default observer(SelectStudentsWithModal);
