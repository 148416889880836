import React, { useState, useRef } from "react";
import { observer } from "mobx-react";
import { FiUser, FiShoppingCart } from "react-icons/fi";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import logoBanner from "../../assets/logo-banner.png";
import UserStore from "../../stores/UserStore";
import AuthStore from "../../stores/AuthStore";
import CartStore from "../../stores/CartStore";
import { Button } from "../-common";
import CartMenu from "./CartMenu";
import "./Header.scss";

const ProfileMenuContent = observer(() => {
  const { isMobile } = useBreakpoints();

  const { firstName } = UserStore?.user || {};

  const signOut = () => AuthStore?.signOut();

  const signOutButton = isMobile ? (
    <Button type="cta" action={signOut}>
      Sign Out
    </Button>
  ) : (
    <div className="profile-menu-item action" onClick={signOut}>
      Sign Out
    </div>
  );

  return (
    <div className="profile-menu-content">
      <div className="profile-menu-section">
        <div className="profile-menu-header">Hi {firstName || "there"}!</div>
        {signOutButton}
      </div>
    </div>
  );
});

const Header = () => {
  const { authenticated } = AuthStore || {};
  const { profilePicture } = UserStore?.user || {};
  const { enrollmentsInCart } = CartStore || {};
  const itemsInCart = !!enrollmentsInCart?.length;
  const itemsInCartIndicator = itemsInCart ? <div className="items-in-cart-indicator" /> : null;

  const [cartMenuOpen, setCartMenuOpen] = useState(false);
  const toggleCartMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    setCartMenuOpen(!cartMenuOpen);
  };
  const closeCartMenu = e => {
    e?.preventDefault();
    e?.stopPropagation();
    setCartMenuOpen(false);
  };

  const goToHome = () => window.open("https://new.letsplaymusicsite.com", "_self"); // per Shelle 6/1/22

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const openProfileMenu = () => setProfileMenuOpen(true);
  const closeProfileMenu = () => setProfileMenuOpen(false);
  const profileButtonRef = useRef();
  useOnClickOutside(profileButtonRef, closeProfileMenu);

  const [profilePictureError, setProfilePictureError] = useState(false);
  const markProfilePictureError = () => setProfilePictureError(true);
  const profileButtonContent =
    !profilePicture || profilePictureError ? (
      <FiUser className="icon" />
    ) : (
      <img src={profilePicture} alt="User profile" className="profile-picture" onError={markProfilePictureError} />
    );
  const profileMenuContent = profileMenuOpen ? <ProfileMenuContent /> : null;
  const profileButton = authenticated ? (
    <div ref={profileButtonRef} className={`profile-button${profileMenuOpen ? " open" : ""}`} onClick={openProfileMenu}>
      {profileButtonContent}
      {profileMenuContent}
    </div>
  ) : null;

  return (
    <div className="header">
      <img src={logoBanner} alt="LPM Logo" className="logo-banner" onClick={goToHome} />
      <div className="right-items">
        {profileButton}
        <div className={`cart-button${cartMenuOpen ? " open" : ""}`} onClick={toggleCartMenu}>
          <FiShoppingCart className="cart-button-icon" />
          {itemsInCartIndicator}
        </div>
      </div>
      <CartMenu isOpen={cartMenuOpen} close={closeCartMenu} />
    </div>
  );
};

export default observer(Header);
