import Amplify, { Auth } from "aws-amplify";
import { COGNITO_CONFIG, API_CONFIG } from "../config";

function initialize() {
  Amplify.configure({
    Auth: COGNITO_CONFIG,
    API: {
      ...API_CONFIG,
      endpoints: API_CONFIG?.endpoints?.map(e => ({
        ...e,
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        })
      }))
    }
  });
}

export default initialize;
