import React from "react";
import { observer } from "mobx-react";
import { Button } from "../../-common";
import "./TeacherTestimonialsModal.scss";

const Testimonial = ({ text, attribution }) => (
  <div className="testimonial">
    <div className="testimonial-text">{text}</div>
    <div className="testimonial-attribution">- {attribution}</div>
  </div>
);

function TeacherTestimonialsModal({ teacher, close }) {
  const testimonials = teacher?.testimonials?.map(t => <Testimonial {...t} />);

  return (
    <div className="teacher-testimonials-modal-wrapper">
      <div className="teacher-testimonials-modal">
        <div className="content">
          <div className="title">
            Testimonials for {teacher?.firstName} {teacher?.lastName}
          </div>
          <div className="testimonials-wrapper">{testimonials}</div>
        </div>
        <div className="bottom-buttons">
          <div className="left-buttons" />
          <div className="right-buttons">
            <Button type="primary" action={close}>
              Close
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(TeacherTestimonialsModal);
