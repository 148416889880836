import React, { useState } from "react";
import { observer } from "mobx-react";
import { Router, Redirect, Link, navigate } from "@reach/router";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import useQueryParams from "../../hooks/useQueryParams";
import useOnEnterPressed from "../../hooks/useOnEnterPressed";
import AuthStore from "../../stores/AuthStore";
import { Input, SecureInput, Spinner, Button } from "../../components/-common";
import ForgotPassword from "./ForgotPassword";
import CompletePasswordReset from "./CompletePasswordReset";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import SignUp from "./SignUp";
import VerifyAccount from "./VerifyAccount";
import "./Auth.scss";

const Auth = observer(() => {
  const { isMobile } = useBreakpoints();
  const { nextcourse } = useQueryParams();

  const [email, setEmail] = useState("");
  const updateEmail = ({ target }) => setEmail(target.value);

  const [password, setPassword] = useState("");
  const updatePassword = ({ target }) => setPassword(target.value);

  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  const attemptAuth = async () => {
    setEmailError(null);
    setPasswordError(null);
    const { success, error } = await AuthStore.signIn(email, password);
    if (success) {
      if (nextcourse) navigate("/student?nextcourse=true");
      else navigate("/students");
    } else {
      if (error === "Incorrect username or password.") {
        setEmailError(" ");
        setPasswordError(error);
      } else if (error === "User does not exist.") {
        setEmailError(error);
      } else {
        setEmailError(" ");
        setPasswordError("Sign in error - double check your entries.");
      }
    }
  };
  useOnEnterPressed(attemptAuth);

  const startSignUpProcess = () => navigate("/auth/signup");

  const buttonContents = AuthStore.loading ? <Spinner /> : "Log In";
  const buttonStyles = AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};

  const instructions = (
    <div className="right-col">
      <h1 className="hero-text">Excellent!</h1>
      <h1 className="hero-text">Next, you'll need to log in.</h1>
      <p className="helper-text">
        Once you've either logged in or registered for an account, we'll get your child's information so we can add them
        to the roster.
      </p>
    </div>
  );

  return (
    <div className="auth">
      {isMobile ? instructions : null}
      <div className="left-col">
        <div className="auth-content">
          <div className="auth-square">
            <div className="auth-inputs">
              <Input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={updateEmail}
                error={emailError}
                autoComplete="email"
              />
              <SecureInput
                type="password"
                placeholder="Password"
                value={password}
                onChange={updatePassword}
                error={passwordError}
                autoComplete="current-password"
              />
              <Button type="cta" className="log-in-button" style={buttonStyles} action={attemptAuth}>
                {buttonContents}
              </Button>
            </div>
            <div className="forgot-password-link">
              <Link to="/auth/resetpassword">Forgot your password?</Link>
            </div>
            <div className="no-account">
              <span>Don't have an account yet?</span>
              <Button type="cta" className="sign-up-button" style={buttonStyles} action={startSignUpProcess}>
                Sign Up
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isMobile ? null : instructions}
    </div>
  );
});

const AuthRouter = () => {
  return (
    <Router primary={false}>
      <Auth path="/" />
      <ForgotPassword path="/resetpassword" />
      <CompletePasswordReset path="/resetpassword/finish" />
      <ResetPasswordSuccess path="/resetpassword/success" />
      <SignUp path="/signup" />
      <VerifyAccount path="/verify" />
      <Redirect from="*" to="/auth" noThrow />
    </Router>
  );
};

export default AuthRouter;
