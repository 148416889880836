import { useState, useEffect } from "react";
import { getLocation } from "../services/GeolocationService";
import ClassMapStore from "../stores/ClassMapStore";

const storageKey = "lpm:enrollment:lastlocation";
const defaultLocation = { lat: 33.4234683, lng: -111.7700528 };

export default function useLocation() {
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState();

  const setDefaultLocation = () => setLocation(defaultLocation);

  useEffect(() => {
    try {
      const locationString = localStorage.getItem(storageKey);
      const cachedLocation = JSON.parse(locationString);
      if (cachedLocation?.lat && cachedLocation?.lng) setLocation(cachedLocation);
      else setDefaultLocation();
    } catch {
      setDefaultLocation();
    }
  }, []);

  const locate = async () => {
    try {
      setLoading(true);
      const { coords: { latitude: lat, longitude: lng } = {} } = await getLocation();
      setLocation({ lat, lng });
      ClassMapStore.setCurrentCenter({ lat, lng });
      ClassMapStore.setCurrentZoom(12);
      const locationString = JSON.stringify({ lat, lng });
      localStorage.setItem(storageKey, locationString);
    } catch {
      // TODO: Handle not having permission or failure
    }
    setLoading(false);
  };

  const manuallySetLocation = location => {
    setLocation(location);
    ClassMapStore.setCurrentCenter(location);
    ClassMapStore.setCurrentZoom(12);
    const locationString = JSON.stringify(location);
    localStorage.setItem(storageKey, locationString);
  };

  return { loading, location, locate, manuallySetLocation };
}
