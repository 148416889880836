import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiHash } from "react-icons/fi";
import { Button, Input } from "../../../-common";
import "./EnterRegistrationCodeModal.scss";

function EnterRegistrationCodeModal({ codeToMatch, class: classObject, selectClass, close }) {
  const [code, setCode] = useState("");
  const updateCode = ({ target }) => setCode(target.value);

  const submitEnabled = code === codeToMatch;
  const submit = () => {
    const select = selectClass(classObject);
    if (submitEnabled && typeof select === "function") select();
  };

  return (
    <div className="enter-registration-code-modal">
      <div className="content">
        <div className="title">This Class Requires a Enrollment Code</div>
        <div className="message">
          If you don't have a code yet, reach out to your teacher and they can provide one for you.
        </div>
        <div className="input-wrapper">
          <Input
            icon={FiHash}
            type="text"
            placeholder="Enrollment Code"
            value={code}
            onChange={updateCode}
            pattern="[0-9]*"
            inputMode="numeric"
          />
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button type="primary" className="send-request-button" action={submit} disabled={!submitEnabled}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(EnterRegistrationCodeModal);
