import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import UserStore from "../../../stores/UserStore";
import { Input, Select } from "../../-common";
import "./PaymentInfoSection.scss";

export const NEW_CARD_OPTION_LABEL = "+ Add a New Card";

const PAYMENT_INPUTS_STYLES = {
  fieldWrapper: { base: { height: 64, width: "100%", marginBottom: 8, zIndex: 99 } },
  inputWrapper: {
    base: {
      padding: "0px 8px",
      boxSizing: "border-box",
      fontSize: 16,
      lineHeight: "34px",
      borderRadius: 2,
      outline: "none",
      border: "none",
      boxShadow: "var(--shadow)"
    },
    focused: {
      borderWidth: 1,
      borderColor: "var(--medium-gray)",
      boxShadow: "var(--shadow)"
    },
    errored: {
      borderWidth: 1,
      borderColor: "var(--medium-gray)",
      boxShadow: "var(--shadow)"
    }
  },
  errorText: { base: { color: "var(--red)", fontSize: 16 } }
};

const cardNumberValidator = ({ cardType }) => {
  if (cardType.displayName === "Visa" || cardType.displayName === "Mastercard") {
    return;
  }
  return "Card must be Visa or Mastercard";
};

const PaymentInfoSection = props => {
  const {
    selectedCard,
    updateSelectedCard,
    enteringNewCard,
    setEnteringNewCard,
    newCardDetails,
    setNewCardDetails,
    actionButton,
    checkoutError,
    hasProductsToShip
  } = props || {};

  const { isMobile } = useBreakpoints();

  const { rawCards, cards } = UserStore || {};
  useEffect(() => {
    if (!rawCards) UserStore?.fetchCardsForUser();
  }, [rawCards]);

  const unsetEnteringNewCard = () => setEnteringNewCard(false);

  const updateCardName = ({ target }) => setNewCardDetails(prev => ({ ...prev, name: target.value }));
  const updateCardNumber = ({ target }) => setNewCardDetails(prev => ({ ...prev, number: target.value }));
  const updateExpDate = ({ target }) => {
    const [expMonth, expYear] = target.value.split(" / ");
    setNewCardDetails(prev => ({ ...prev, expMonth, expYear }));
  };
  const updateCVV = ({ target }) => setNewCardDetails(prev => ({ ...prev, cvv: target.value }));
  const updateCardZip = ({ target }) => setNewCardDetails(prev => ({ ...prev, zip: target.value }));

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getZIPProps,
    meta
  } = usePaymentInputs({ cardNumberValidator });

  const cardOptions = cards?.map(c => c?.label)?.concat(NEW_CARD_OPTION_LABEL);

  const cardInputs = isMobile ? (
    <>
      <div className="mobile-card-input-wrapper">
        <svg {...getCardImageProps({ images })} />
        <input
          {...getCardNumberProps({ onChange: updateCardNumber })}
          style={{ flex: 1 }}
          pattern="[0-9]*"
          inputMode="numeric"
          autoComplete="cc-number"
        />
      </div>
      <div className="mobile-card-aux-inputs-wrapper">
        <input
          {...getExpiryDateProps({ onChange: updateExpDate })}
          style={{ marginRight: 12 }}
          pattern="[0-9]*"
          inputMode="numeric"
          autoComplete="cc-exp"
        />
        <input
          {...getCVCProps({ onChange: updateCVV })}
          style={{ marginRight: 12 }}
          pattern="[0-9]*"
          inputMode="numeric"
          autoComplete="cc-csc"
        />
        <input
          {...getZIPProps({ onChange: updateCardZip })}
          pattern="[0-9]*"
          inputMode="numeric"
          autoComplete="postal-code"
        />
      </div>
      {meta.isTouched && meta.error && <span className="mobile-card-error-text">Error: {meta.error}</span>}
    </>
  ) : (
    <PaymentInputsWrapper {...wrapperProps} styles={PAYMENT_INPUTS_STYLES}>
      <svg {...getCardImageProps({ images })} />
      <input
        {...getCardNumberProps({ onChange: updateCardNumber })}
        style={{ flex: 1 }}
        pattern="[0-9]*"
        inputMode="numeric"
        autoComplete="cc-number"
      />
      <input
        {...getExpiryDateProps({ onChange: updateExpDate })}
        style={{ marginRight: 12 }}
        pattern="[0-9]*"
        inputMode="numeric"
        autoComplete="cc-exp"
      />
      <input
        {...getCVCProps({ onChange: updateCVV })}
        style={{ marginRight: 12 }}
        pattern="[0-9]*"
        inputMode="numeric"
        autoComplete="cc-csc"
      />
      <input
        {...getZIPProps({ onChange: updateCardZip })}
        pattern="[0-9]*"
        inputMode="numeric"
        autoComplete="postal-code"
      />
    </PaymentInputsWrapper>
  );

  let paymentInfoContent = enteringNewCard ? (
    <div className="new-card-info-wrapper">
      <div className="input-row">
        <div className="input-wrapper">
          <div className="input-title">Name on Card</div>
          <Input placeholder="Jane H Doe" value={newCardDetails?.name} onChange={updateCardName} />
        </div>
      </div>
      <div className="input-row">
        <div className="input-wrapper">
          <div className="input-title">Card Info</div>
          {cardInputs}
        </div>
      </div>
      <div className="use-existing-card">
        <span onClick={unsetEnteringNewCard}>...or use a saved card</span>
      </div>
    </div>
  ) : (
    <div className="payment-method-select-wrapper">
      <Select
        placeholder="Select Payment Method..."
        options={cardOptions}
        value={selectedCard?.label}
        onChange={updateSelectedCard}
      />
    </div>
  );

  if (!hasProductsToShip) paymentInfoContent = <div className="no-purchase-necessary">(not necessary)</div>;

  const error = checkoutError ? (
    <div className={`checkout-error${enteringNewCard ? " new-card" : ""}`}>{checkoutError}</div>
  ) : null;

  return (
    <div className="col payment-information">
      <div className="col-header">Payment Information</div>
      {paymentInfoContent}
      {error}
      {isMobile ? null : actionButton}
    </div>
  );
};

export default observer(PaymentInfoSection);
