import React from "react";
import Checkbox from "../Checkbox";
import "./CheckboxGroup.scss";

const CheckboxGroup = ({ options, selections, onSelectionsChanged }) => {
  const updateSelections = ([selected, option]) => {
    const newSelections = selected
      ? Array.from(new Set(selections.concat([option])))
      : selections.filter(s => s !== option);
    onSelectionsChanged(newSelections);
  };

  const optionLines = options.map((o, i) => {
    const selected = selections.includes(o);
    return <Checkbox value={o} selected={selected} onChange={updateSelections} key={i} />;
  });

  return <div className="lpm-checkbox-group">{optionLines}</div>;
};

export default CheckboxGroup;
