import { useEffect, useState } from "react";
import card from "creditcard";

export default function useCardValid({ cardDetails, setCardDetails }) {
  const { name: cardName, number: cardNumber, expMonth, expYear, cvv, zip } = cardDetails || {};

  const [cardValid, setCardValid] = useState(false);

  useEffect(() => {
    const validCard = card.validate(cardNumber);
    const validExp = card.expiry(expMonth, (expYear || "").padStart(4, "20"));
    const brand = card.cardscheme(cardNumber);
    setCardDetails(prev => ({ ...prev, brand }));
    const validBrand = ["mastercard", "visa"]?.includes(brand?.toLowerCase());
    const validCVV = brand === "American Express" ? cvv?.length === 4 : cvv?.length === 3;
    const validZip = zip?.length === 5;
    const cardIsValid = !!cardName && validBrand && validCard && validExp && validCVV && validZip;
    setCardValid(cardIsValid);
  }, [setCardDetails, cardName, cardNumber, expMonth, expYear, cvv, zip]);

  return cardValid;
}
