import React, { useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { FiHash } from "react-icons/fi";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import useOnEnterPressed from "../../../hooks/useOnEnterPressed";
import AuthStore from "../../../stores/AuthStore";
import { Input, Spinner, Button } from "../../-common";
import "./VerifyAccount.scss";

const VerifyAccount = observer(({ location }) => {
  const { isMobile } = useBreakpoints();

  const { email } = location?.state || {};
  const [code, setCode] = useState("");
  const updateCode = ({ target }) => {
    setError();
    setCode(target.value);
  };

  const [error, setError] = useState();

  const verificationEnabled = code?.length === 6 && Number(code);
  const attemptVerification = async () => {
    if (verificationEnabled) {
      setError();
      const { success, alreadyConfirmed, error: errorFromStore } = await AuthStore.verifyAccount(code);
      if (success) {
        navigate("/students");
      } else if (alreadyConfirmed) {
        navigate("/auth");
      } else {
        setError(errorFromStore);
      }
    }
  };
  useOnEnterPressed(attemptVerification);

  const resendCode = () => AuthStore?.resendVerificationCode();

  const instructions = (
    <div className="right-col">
      <h1 className="hero-text">Almost there!</h1>
      <p className="helper-text">We've sent your verification code to {email}</p>
      <p className="helper-text">Enter your verification code and we'll get you right on to setting up your kids.</p>
    </div>
  );

  const buttonContents = AuthStore.loading ? <Spinner /> : "Verify";
  const buttonStyles = AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};

  return (
    <div className="verify-account">
      {isMobile ? instructions : null}
      <div className="left-col">
        <div className="verify-content">
          <div className="verify-square">
            <div className="verify-inputs">
              <Input
                icon={FiHash}
                type="text"
                placeholder="Verification Code"
                value={code}
                onChange={updateCode}
                error={error}
                pattern="[0-9]*"
                inputMode="numeric"
              />
              <Button
                type="cta"
                className="verify-button"
                style={buttonStyles}
                action={attemptVerification}
                disabled={!verificationEnabled}
              >
                {buttonContents}
              </Button>
            </div>
            <div className="no-account">
              <span>Didn't get an email?</span>
              <Button type="cta" className="sign-up-button" style={buttonStyles} action={resendCode}>
                Resend Code
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isMobile ? null : instructions}
    </div>
  );
});

export default VerifyAccount;
