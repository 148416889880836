import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { FiUser } from "react-icons/fi";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import AuthStore from "../../stores/AuthStore";
import UserStore from "../../stores/UserStore";
import CartStore from "../../stores/CartStore";
import { Spinner, Button } from "../-common";
import "./SelectStudentForNextClass.scss";

const LastClassInfo = ({ period, sessionTime, courseLogo, teacher }) => {
  const [courseLogoError, setCourseLogoError] = useState(false);
  const markCourseLogoError = () => setCourseLogoError(true);
  const courseLogoComponent = courseLogoError ? (
    <div className={`course-logo placeholder`}>
      <FiUser size={36} />
    </div>
  ) : (
    <img className="course-logo" src={courseLogo} alt="Course logo" onError={markCourseLogoError} />
  );

  return (
    <div className="last-class-info">
      {courseLogoComponent}
      <div>
        <div className="bold">Most Recent Class</div>
        <div className="aux">
          {teacher?.firstName} {teacher?.lastName}
        </div>
        <div className="aux">{period}</div>
        <div className="aux">{sessionTime}</div>
      </div>
    </div>
  );
};

const ChildItem = ({ child, selected, selectChild }) => {
  const { isMobile } = useBreakpoints();
  const { id, firstName, lastName, birthdayText, gender, lastClass } = child || {};

  let genderColor = "var(--medium-gray)";
  if (gender === "female") genderColor = "var(--female-pink)";
  if (gender === "male") genderColor = "var(--male-blue)";

  const select = () => selectChild(id);

  const content = isMobile ? (
    <>
      <div className="top-info">
        <div className={`profile-image ${gender ?? ""}`}>
          <FiUser color={genderColor} size={40} />
        </div>
        <div className="info-container">
          <div className="child-item-name">
            {firstName} {lastName}
          </div>
          <div className="child-item-birthday">{birthdayText}</div>
        </div>
      </div>
      <LastClassInfo {...lastClass} />
    </>
  ) : (
    <>
      <div className={`profile-image ${gender ?? ""}`}>
        <FiUser color={genderColor} size={40} />
      </div>
      <div className="info-container">
        <div className="child-item-name">
          {firstName} {lastName}
        </div>
        <div className="child-item-birthday">{birthdayText}</div>
      </div>
      <div className="flexer" />
      <LastClassInfo {...lastClass} />
    </>
  );

  return (
    <div className={`child-item-wrapper${selected ? " selected" : ""}`} onClick={select}>
      <div className="child-item-content">{content}</div>
    </div>
  );
};

const SelectStudentForNextClass = observer(() => {
  const { authenticated } = AuthStore || {};
  useEffect(() => {
    if (!authenticated) navigate("/");
  }, [authenticated]);

  const { loadingChildren, children } = UserStore || {};

  const [selectedChildId, setSelectedChildId] = useState();
  const selectChild = childId => setSelectedChildId(childId);

  const continueEnabled = !!selectedChildId;
  const continueToSelectClass = () => {
    const child = children?.find(c => c?.id === selectedChildId);
    const teacherId = child?.lastClass?.teacher?.id;
    if (continueEnabled && child && teacherId) {
      CartStore.addEnrollmentToCart({ students: [child] });
      navigate(`/teachers/${teacherId}?from=continue&continuingStudentId=${child?.id}`);
    }
  };

  const childItems = children
    ?.filter(c => !!c?.lastClass)
    ?.map(child => {
      return (
        <ChildItem child={child} selected={selectedChildId === child?.id} selectChild={selectChild} key={child.id} />
      );
    });

  const selectChildrenContents = loadingChildren ? (
    <div className="loading-children">
      <Spinner alt />
    </div>
  ) : (
    <>
      <div className="list-container">{childItems}</div>
      <div className="buttons-row">
        <div className="cta-button-wrapper">
          <Button type="cta" action={continueToSelectClass} disabled={!continueEnabled}>
            Select Next Class
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <div className="select-student-for-next-class-wrapper">
      <div className="left-col">
        <h1 className="hero-text">Which child would you like to enroll?</h1>
        <p className="helper-text">
          Don't worry - if you have more children that you want to put in other classes, we'll come back for them. For
          now, who would you like to sign up?
        </p>
      </div>
      <div className="right-col">{selectChildrenContents}</div>
    </div>
  );
});

export default SelectStudentForNextClass;
