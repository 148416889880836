import React, { useRef } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import numeral from "numeral";
import usePortal from "../../../hooks/usePortal";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import CartStore from "../../../stores/CartStore";
import AuthStore from "../../../stores/AuthStore";
import { Button } from "../../-common";
import "./CartMenu.scss";

const EnrollmentItem = ({ id, teacher, class: classItem, students, bundles, total, close }) => {
  const { name: courseName } = classItem?.course || {};

  const [lastStudent, ...restOfStudents] = (students || [])?.reverse();
  const firstStudentNames = restOfStudents
    ?.map(s => s?.firstName)
    ?.reverse()
    ?.join(", ");
  const lastStudentLabel =
    (!!restOfStudents?.length ? `${students?.length > 2 ? "," : ""} and ` : "") + (lastStudent?.firstName || "");
  const studentNames = !!firstStudentNames || !!lastStudentLabel ? firstStudentNames + lastStudentLabel : "";

  const { firstName: teacherFirstName, lastName: teacherLastName } = teacher || {};

  const hasCourse = !!teacher && !!classItem;
  const hasStudents = !!students?.length;
  const hasBundles = !!Object.values(bundles || {})?.length;

  const continueEnrollment = () => {
    CartStore?.setActiveEnrollmentId(id);
    if (!AuthStore?.authenticated) {
      navigate("/auth");
    } else if (hasStudents && !hasCourse) {
      const studentId = students?.[0]?.id;
      const teacherId = students?.[0]?.lastClass?.teacher?.id;
      navigate(`/teachers/${teacherId}?from=continue&continuingStudentId=${studentId}`);
    } else if (!hasStudents) {
      navigate("/students");
    } else if (!hasBundles) {
      navigate("/materials");
    }
    close();
  };

  const updateMaterials = () => {
    CartStore?.setActiveEnrollmentId(id);
    navigate("/materials");
    close();
  };

  const removeEnrollment = () => {
    CartStore?.removeEnrollmentFromCart(id);
    if (!CartStore?.enrollmentsInCart?.length) close();
  };

  const teacherName = teacherFirstName && teacherLastName ? `${teacherFirstName} ${teacherLastName}` : null;
  const studentNameLabel = studentNames ? `for ${studentNames}` : "(no children selected yet)";
  const totalLabel = total ? <div className="total">{numeral(total / 100).format("$0,00.00")}</div> : null;

  const titleLabel =
    courseName && teacherName ? `${courseName} with ${teacherName}` : "(no teacher or class selected yet)";

  const continueButton =
    !hasStudents || !hasBundles ? (
      <Button type="cta" action={continueEnrollment}>
        Finish Enrolling
      </Button>
    ) : null;

  const updateMaterialsButton =
    hasStudents && hasBundles ? (
      <Button type="cta" action={updateMaterials}>
        Update Materials
      </Button>
    ) : null;

  return (
    <div className="enrollment-item">
      <div className="title">{titleLabel}</div>
      <div className="student-name">{studentNameLabel}</div>
      {totalLabel}
      <div className="action-buttons-wrapper">
        {updateMaterialsButton}
        <Button type="negative" action={removeEnrollment}>
          Remove
        </Button>
        {continueButton}
      </div>
    </div>
  );
};

const CartMenu = observer(({ isOpen, close }) => {
  const portalTarget = usePortal("cart-menu-container");
  const menuRef = useRef();
  useOnClickOutside(menuRef, close);

  const goToCheckout = () => {
    navigate("/checkout");
    close();
  };

  const hasReadyEnrollments = !!CartStore?.enrollmentsReadyForCheckout?.length;
  const hasInProgressEnrollments = !!CartStore?.inProgressEnrollments?.length;

  const zeroState =
    !hasReadyEnrollments && !hasInProgressEnrollments ? (
      <div className="zero-state">There's nothing in your cart yet!</div>
    ) : null;

  const readyTitle = hasReadyEnrollments ? <div className="cart-menu-header">Ready for Checkout</div> : null;
  const readyEnrollmentItems = CartStore?.enrollmentsReadyForCheckout.map(enrollment => (
    <EnrollmentItem {...enrollment} close={close} key={enrollment?.id} />
  ));

  const inProgressTitle = hasInProgressEnrollments ? <div className="cart-menu-header">In Progress</div> : null;
  const inProgressEnrollmentItems = CartStore?.inProgressEnrollments.map(enrollment => (
    <EnrollmentItem {...enrollment} close={close} key={enrollment?.id} />
  ));

  const checkoutSectionTitle = hasReadyEnrollments ? <div className="cart-menu-header">Summary</div> : null;
  const checkoutSection = hasReadyEnrollments ? (
    <div className="checkout-section">
      <div className="shipping-total">Shipping: {numeral(CartStore?.shippingTotal / 100).format("$0,00.00")}</div>
      <div className="total">Total: {numeral(CartStore?.total / 100).format("$0,00.00")}</div>
      <div className="checkout-button-wrapper">
        <Button type="cta" className="checkout-button" action={goToCheckout}>
          Go To Checkout
        </Button>
      </div>
    </div>
  ) : null;

  const contents = isOpen ? (
    <div className="cart-menu" ref={menuRef}>
      {zeroState}
      {readyTitle}
      {readyEnrollmentItems}
      {inProgressTitle}
      {inProgressEnrollmentItems}
      {checkoutSectionTitle}
      {checkoutSection}
    </div>
  ) : null;
  return createPortal(contents, portalTarget);
});

export default CartMenu;
