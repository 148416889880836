import { useState } from "react";
import { createPortal } from "react-dom";
import { FullScreenModal } from "../components/-common";
import usePortal from "./usePortal";

export default function useModal(ModalComponent, props, { portalElementId, big } = {}) {
  const [isOpen, setIsOpen] = useState(false);
  const [openFunctionProps, setOpenFunctionProps] = useState({});
  const openModal = (openProps = {}) => {
    try {
      setOpenFunctionProps({ ...openFunctionProps, ...openProps });
    } catch {}

    setIsOpen(true);
  };
  const closeModal = () => setIsOpen(false);

  const Modal = () => {
    return (
      <FullScreenModal isOpen={true} big={big} close={closeModal}>
        <ModalComponent {...{ close: closeModal, ...props, ...openFunctionProps }} />
      </FullScreenModal>
    );
  };

  const portalTarget = usePortal(portalElementId || "modal-container");
  const contents = isOpen ? <Modal {...props} /> : null;
  const ModalContainer = () => createPortal(contents, portalTarget);

  return { isOpen, openModal, closeModal, Modal: ModalContainer };
}
