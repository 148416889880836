import React, { useRef, useState } from "react";
import { observer } from "mobx-react";
import { Button, Spinner } from "../../-common";
import CartStore from "../../../stores/CartStore";
import "./CouponItem.scss";

const CouponItem = () => {
  const ref = useRef(null);
  const [applying, setApplying] = useState(false);
  const [couponMessage, setCouponMessage] = useState("");

  const applyCoupon = async () => {
    setApplying(true);
    const couponCode = ref.current.value;
    await CartStore.applyCoupon(couponCode);
    setCouponMessage(CartStore.couponMessage);
    setApplying(false);
  };

  const buttonContent = applying ? <Spinner /> : "Apply"; // prettier-ignore
  const message = couponMessage ? <div className="message">{couponMessage}</div> : null;

  return (
    <div className="coupon-item">
      <div className="discount-container">
        <input type="text" placeholder="Discount Code" className="discount-input" ref={ref} />
        <Button type="cta" action={applyCoupon} disabled={applying}>
          {buttonContent}
        </Button>
      </div>
      {message}
    </div>
  );
};

export default observer(CouponItem);
