import React from "react";
import "./PieTimer.scss";

export default class PieTimer extends React.Component {
  constructor(props) {
    super(props);

    // This part might be confusing:
    // If n==0, do infinite loops
    // In other cases where n is set, do n loops
    // If n is not set, do 1 loop
    // Do it this way to prevent mixing n==0 and !n

    const duration = props.duration || 1000;
    const n = props.loops === 0 ? 0 : props.loops ? props.loops : 1;
    const end = Date.now() + duration * n;
    const totalDuration = duration * n;
    this.state = { duration, loops: n, end, totalDuration, borderShape: "", loaderShape: "" };
  }

  componentDidMount() {
    this.frame();
  }

  // Animate frame by frame
  frame() {
    const current = Date.now();
    let remaining = this.state.end - current;
    // Now set rotation rate
    // E.g. 50% of first loop returns 1.5
    // E.g. 75% of sixth loop returns 6.75
    // Has to return >0 for SVG to be drawn correctly
    // If you need the current loop, use Math.floor(rate)

    let rate = this.state.loops + 1 - remaining / this.state.duration;

    // As requestAnimationFrame will draw whenever capable,
    // the animation might end before it reaches 100%.
    // Let's simulate completeness on the last visual
    // frame of the loop, regardless of actual progress
    if (remaining < 60) {
      // 1.0 might break, set to slightly lower than 1
      // Update: Set to slightly lower than n instead
      this.draw(this.state.loops - 0.0001);

      // Stop animating when we reach the total number loops
      if (remaining < this.state.totalDuration && this.state.loops) {
        const { onComplete } = this.props;
        if (onComplete && typeof onComplete === "function") {
          onComplete();
        }
        return;
      }
    }

    if (this.props.reverse && this.props.reverse === true) {
      rate = 360 - rate;
    }

    this.draw(rate);

    // Draw after requesting the next frame
    requestAnimationFrame(this.frame.bind(this));
  }

  draw(rate) {
    const { width, height, reverse } = this.props;
    const angle = (360 * rate) % 360;

    let rad = (angle * Math.PI) / 180,
      x = Math.sin(rad) * (width / 2),
      y = Math.cos(rad) * -(height / 2),
      mid = angle > 180 ? 1 : 0,
      sweepDirection = 1;

    if (reverse && reverse === true) {
      mid = Math.abs(mid - 1);
      sweepDirection = 0;
    }

    const shape =
      "M 0 0 v " +
      -(height / 2) +
      " A " +
      width / 2 +
      " " +
      width / 2 +
      " 1 " +
      mid +
      " " +
      sweepDirection +
      " " +
      x +
      " " +
      y +
      " z";

    this.setState({ borderShape: shape, loaderShape: shape });
  }

  render() {
    const { width, height, color = "var(--red)", backgroundColor = "var(--white)" } = this.props;
    return (
      <div className="pie-timer">
        <svg width={width} height={width} viewBox={"0 0 " + width + " " + height} fill={color}>
          <circle cx={width / 2} cy={height / 2} r={width / 2.1} />
          <path
            className="svg-border"
            fill={backgroundColor}
            transform={"translate(" + width / 2 + " " + height / 2 + ")"}
            d={this.state.borderShape}
          />
          <path
            className="svg-loader"
            fill={backgroundColor}
            transform={"translate(" + width / 2 + " " + height / 2 + ")  scale(.85)"}
            d={this.state.loaderShape}
          />
        </svg>
      </div>
    );
  }
}
