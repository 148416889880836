import React from "react";
import { observer } from "mobx-react";
import numeral from "numeral";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import CartStore from "../../../stores/CartStore";
import "./AZTaxItem.scss";

const AZTaxItem = () => {
  const { isMobile } = useBreakpoints();

  const label = "Percentage - " + numeral(CartStore?.AZTaxPercentage).format("0.00%");
  const formattedTotal = numeral(CartStore?.tax / 100).format("$0,0.00");

  const totalLabel = (
    <div className="total">
      {isMobile ? "TOTAL: " : ""}
      {formattedTotal}
    </div>
  );

  return (
    <div className="checkout-az-tax-item">
      <div className="az-tax-item-title">Arizona Sales Tax</div>
      <div className="late-fee-line">{label}</div>
      <div className="bottom-row">{totalLabel}</div>
    </div>
  );
};

export default observer(AZTaxItem);
