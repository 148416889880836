import { makeAutoObservable } from "mobx";
import { Auth } from "aws-amplify";
import { v4 as uuidV4 } from "uuid";
import { navigate } from "@reach/router";
import { request } from "../utils";

const STORAGE_KEY = "lpm:verification";

class AuthStore {
  constructor() {
    makeAutoObservable(this);
    this.initialize();
    this.checkForAuthenticatedUser();
  }

  authDetermined = false;
  authenticated = false;
  user = {};

  localCredentials = {};

  initialize() {
    try {
      const localCredentialsBase64String = localStorage.getItem(STORAGE_KEY);
      const base64CredentialBuffer = Buffer.from(localCredentialsBase64String, "base64");
      const stringifiedCredentialObject = base64CredentialBuffer.toString("ascii");
      this.localCredentials = JSON.parse(stringifiedCredentialObject) || {};
    } catch {
      this.localCredentials = {};
    }
  }

  setLocalCredentials(credentialObject) {
    this.localCredentials = credentialObject;
    const credentialBuffer = Buffer.from(JSON.stringify(credentialObject));
    const base64Credential = credentialBuffer.toString("base64");
    localStorage.setItem(STORAGE_KEY, base64Credential);
  }

  get sub() {
    return this.user?.attributes?.sub;
  }

  get id() {
    return this.user?.attributes?.["custom:lpmId"];
  }

  get infusionsoftId() {
    return this.user?.attributes?.["custom:infusionsoftId"];
  }

  get email() {
    return this.user?.attributes?.["email"];
  }

  loading = false;

  async checkForAuthenticatedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (user) {
        this.authenticated = true;
        this.user = user;
      } else {
        this.authenticated = false;
        this.user = {};
      }
    } catch (err) {
      // This also means user isn't authenticated
      this.authenticated = false;
    }

    this.authDetermined = true;

    // this.authenticated = true;
    // this.authDetermined = true;
  }

  async signIn(username, password) {
    this.loading = true;
    try {
      await Auth.signIn(username || "-", password || "-");
      let user = await Auth.currentAuthenticatedUser();

      if (!user?.attributes?.["custom:lpmId"]) {
        try {
          await request.post("/users/me/sync");
          await Auth.signIn(username || "-", password || "-");
          user = await Auth.currentAuthenticatedUser();
          if (!user?.attributes?.["custom:lpmId"]) throw new Error("Unable to sync.");
        } catch (err) {
          if (err?.message === "Unable to sync.") {
            await this.signOut();
            navigate("/accounterror");
            return false;
          } else {
            throw err;
          }
        }
      }

      this.user = user;
      this.authenticated = true;
      this.loading = false;
      this.setLocalCredentials({});
      return { success: true };
    } catch (err) {
      console.warn(err);

      try {
        await this.signOut();
      } catch {}

      this.authenticated = false;
      this.loading = false;
      return { success: false, error: err.message };
    }
  }

  async signUp(params) {
    const { firstName, lastName, email, phone, address1, address2, city, state, zip, password, howDidYouHearAboutUs } =
      params || {};

    this.loading = true;

    let success, error;
    try {
      const username = uuidV4();
      await Auth.signUp({ username, password, attributes: { email, phone_number: phone } });
      await request.unauthenticated.post("/accounts", {
        username,
        firstName,
        lastName,
        email,
        phone,
        address1,
        address2,
        city,
        state,
        zip,
        howDidYouHearAboutUs
      });
      success = true;
      this.setLocalCredentials({ username, email, password });
    } catch (err) {
      console.warn(err);
      success = false;
      if (err.message === "PreSignUp failed with error A user with the same email address exists.") {
        error = "User account with the same email already exists. Please log in or reset password instead.";
      } else {
        error = err.message;
      }
    }

    this.loading = false;
    return { success, error };
  }

  async resendVerificationCode() {
    let success, error;

    this.loading = true;
    try {
      await Auth.resendSignUp(this.localCredentials?.username);
      success = true;
    } catch (err) {
      error = err.message;
      success = false;
    }

    this.loading = false;
    return { success, error };
  }

  async verifyAccount(code) {
    this.loading = true;
    const { username, password } = this.localCredentials || {};
    try {
      await Auth.confirmSignUp(username, code, { forceAliasCreation: true });
      await this.signIn(username, password);
      this.setLocalCredentials({});
      return { success: true };
    } catch (err) {
      if (err.message === "User cannot be confirmed. Current status is CONFIRMED") {
        try {
          await this.signIn(username, password);
        } catch (err) {
          return { alreadyConfirmed: true };
        }
      } else {
        this.loading = false;
        return { success: false, error: err.message };
      }
    }
  }

  emailForPasswordReset = null;
  async sendResetPasswordCode(email) {
    let success, error;

    this.loading = true;
    try {
      await Auth.forgotPassword(email);
      this.emailForPasswordReset = email;
      success = true;
    } catch (err) {
      success = false;
      this.emailForPasswordReset = null;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async resetPassword(code, newPassword) {
    this.loading = true;
    let success, error;

    try {
      await Auth.forgotPasswordSubmit(this.emailForPasswordReset, code, newPassword);
      await this.signIn(this.emailForPasswordReset, newPassword);
      this.emailForPasswordReset = null;
      success = true;
    } catch (err) {
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async signOut() {
    try {
      await Auth.signOut();
      this.user = {};
      this.authenticated = false;
    } catch (err) {
      console.warn(err);
    }
  }
}

export default new AuthStore();
