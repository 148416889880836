import React from "react";
import { observer } from "mobx-react";
import numeral from "numeral";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import CartStore from "../../../stores/CartStore";
import "./ShippingItem.scss";

const ShippingItem = () => {
  const { isMobile } = useBreakpoints();

  const shippingLines = CartStore?.shippingItems?.map(({ id, name, price, quantity }, i) => {
    const label = quantity + "x " + name + " - " + numeral((price * quantity) / 100).format("$0,0.00");
    return (
      <div className="shipping-line" key={id || i}>
        {label}
      </div>
    );
  });

  const totalLabel = CartStore?.shippingTotal ? (
    <div className="total">
      {isMobile ? "TOTAL: " : ""}
      {numeral(CartStore?.shippingTotal / 100).format("$0,00.00")}
    </div>
  ) : null;

  return (
    <div className="checkout-shipping-item">
      <div className="shipping-item-title">Shipping</div>
      {shippingLines}
      <div className="bottom-row">{totalLabel}</div>
    </div>
  );
};

export default observer(ShippingItem);
