import { useState, useEffect } from "react";

export default function usePlatform() {
  const [platform, setPlatform] = useState();

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua?.includes("mac os") || ua?.includes("iphone")) setPlatform("ios");
    else setPlatform("android");
  }, [setPlatform]);

  return platform;
}
