import React from "react";
import { observer } from "mobx-react";
import useQueryParams from "../../hooks/useQueryParams";
import useTeacherListingModals from "../../hooks/useTeacherListingModals";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import ListSearch from "./ListSearch";
import GeoSearch from "./GeoSearch";

const Search = observer(() => {
  const { list } = useQueryParams();
  const { isMobile } = useBreakpoints();

  const {
    TeacherBioModal,
    setTeacherAndOpenBioModal,
    TeacherTestimonialsModal,
    setTeacherAndOpenTestimonialsModal,
    SampleClassModal,
    setTeacherAndOpenSampleClassModal
  } = useTeacherListingModals(isMobile);

  const commonProps = {
    openTeacherBio: setTeacherAndOpenBioModal,
    openTeacherTestimonials: setTeacherAndOpenTestimonialsModal,
    openSampleClassForTeacher: setTeacherAndOpenSampleClassModal
  };
  const pageView = !!list ? "list" : "full";
  const mainContents = pageView === "list" ? <ListSearch {...commonProps} /> : <GeoSearch {...commonProps} />;

  return (
    <>
      {mainContents}
      <TeacherBioModal />
      <TeacherTestimonialsModal />
      <SampleClassModal />
    </>
  );
});

export default Search;
