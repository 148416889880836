import React, { useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import useOnEnterPressed from "../../../hooks/useOnEnterPressed";
import AuthStore from "../../../stores/AuthStore";
import { Input, SecureInput, Spinner, Button } from "../../-common";
import "./CompletePasswordReset.scss";

const CompletePasswordReset = observer(() => {
  const { isMobile } = useBreakpoints();

  const [code, setCode] = useState("");
  const updateCode = ({ target }) => {
    setError();
    setCode(target.value);
  };

  const [password, setPassword] = useState("");
  const updatePassword = ({ target }) => {
    setError();
    setPassword(target.value);
  };

  const [confirmPassword, setConfirmPassword] = useState("");
  const updateConfirmPassword = ({ target }) => {
    setError();
    setConfirmPassword(target.value);
  };

  const [error, setError] = useState();

  const resetEnabled = !isNaN(code) && code?.length === 6 && password?.length >= 6 && password === confirmPassword;
  const resetPassword = async () => {
    if (resetEnabled) {
      setError();
      const { success, error: errorFromStore } = await AuthStore?.resetPassword(code, password);
      if (success) navigate("/resetpassword/success");
      else if (errorFromStore) setError(errorFromStore);
    }
  };
  useOnEnterPressed(resetPassword);

  const backToLogIn = () => navigate("/auth");

  const buttonContents = AuthStore.loading ? <Spinner /> : "Reset Password";
  const buttonStyles = AuthStore.loading ? { opacity: 0.7, pointerEvents: "none" } : {};

  const instructions = (
    <div className="left-col">
      <h1 className="hero-text">Last step!</h1>
      <p className="helper-text">
        Almost there - enter the code we sent you and your new password and we'll have you on your way.
      </p>
    </div>
  );

  return (
    <div className="complete-password-reset">
      {isMobile ? instructions : null}
      <div className="right-col">
        <div className="complete-password-reset-content">
          <div className="complete-password-reset-square">
            <div className="complete-password-reset-inputs">
              <div className="inputs-row-title">Verification Code</div>
              <div className="inputs-group">
                <Input
                  type="text"
                  placeholder="### ###"
                  value={code}
                  onChange={updateCode}
                  error={!!error}
                  autoComplete="one-time-code"
                  pattern="[0-9]*"
                  inputMode="numeric"
                />
              </div>
              <div className="inputs-row-title">New Password</div>
              <div className="inputs-row-subtitle">Your password should have at least six characters.</div>
              <div className="inputs-group last">
                <SecureInput
                  type="password"
                  placeholder="$ecr3tP4ssw0rd"
                  value={password}
                  onChange={updatePassword}
                  error={!!error}
                />
                <SecureInput
                  type="password"
                  placeholder="Enter one more time to confirm"
                  value={confirmPassword}
                  onChange={updateConfirmPassword}
                  error={error}
                />
              </div>
              <Button
                type="cta"
                className="send-code-button"
                style={buttonStyles}
                action={resetPassword}
                disabled={!resetEnabled}
              >
                {buttonContents}
              </Button>
            </div>
            <div className="no-account">
              <span>Suddenly remembered the old one?</span>
              <Button type="cta" className="sign-up-button" action={backToLogIn}>
                Back to Log In
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isMobile ? null : instructions}
    </div>
  );
});

export default CompletePasswordReset;
