import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import Input from "../Input";
import "./SecureInput.scss";

const SecureInput = ({ icon: _, type: __, ...props }) => {
  const [showing, setShowing] = useState(false);
  const toggleShowing = () => setShowing(!showing);

  const Icon = showing ? FiEyeOff : FiEye;
  const icon = <Icon className="lpm-input-icon secure" onClick={toggleShowing} />;
  const type = showing ? "text" : "password";

  return <Input icon={icon} type={type} {...props} />;
};

export default SecureInput;
