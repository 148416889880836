import React from "react";
import { observer } from "mobx-react";
import { Router, Redirect, LocationProvider } from "@reach/router";
import { ErrorBoundary } from "react-error-boundary";
import logError from "../services/LogErrorService";
import { BreakpointProvider } from "../hooks/useBreakpoints";
import Header from "../components/Header";
import Personas from "../components/@Personas";
import Search from "../components/@Search";
import TeacherDetails from "../components/@TeacherDetails";
import Auth from "../components/@Auth";
import SelectStudents from "../components/@SelectStudents";
import SelectStudentForNextClass from "../components/@SelectStudentForNextClass";
import SelectMaterials from "../components/@SelectMaterials";
import Checkout from "../components/@Checkout";
import ThankYou from "../components/@ThankYou";
import AccountError from "../components/@AccountError";
import "./App.scss";

const mediaQueries = { isMobile: "(max-width: 568px)" };

const ErrorPage = () => (
  <div className="error-page">
    <div className="title">Oops, looks like something's not right here.</div>
    <div className="message">
      Sorry about that - give the page a refresh and try again. If this keeps up, reach out to us at .
    </div>
  </div>
);

const App = observer(() => {
  return (
    <>
      <Header />
      <div className="main-content">
        <Router>
          <Personas path="/" />
          <Search path="/search" />
          <TeacherDetails path="/teachers/:teacherId" />
          <Auth path="/auth/*" />
          <SelectStudents path="/students" />
          <SelectStudentForNextClass path="/student" />
          <SelectMaterials path="/materials" />
          <Checkout path="/checkout" />
          <ThankYou path="/thankyou" />
          <AccountError path="/accounterror" />
          <Redirect from="*" to="/" noThrow />
        </Router>
      </div>
    </>
  );
});

function ProvidersWrappedApp() {
  const reportError = async (error, errorInfo) => {
    await logError({ error, errorInfo });
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorPage} onError={reportError}>
      <BreakpointProvider queries={mediaQueries}>
        <LocationProvider>
          <App />
        </LocationProvider>
      </BreakpointProvider>
    </ErrorBoundary>
  );
}

export default ProvidersWrappedApp;
