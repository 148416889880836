import React from "react";
import ReactDOM from "react-dom";
import { configure as configureMobX } from "mobx";
import initialzeAmplify from "./services/AmplifyService";
import App from "./app";
import "./styles/index.scss";

configureMobX({ enforceActions: "never" });
initialzeAmplify();

ReactDOM.render(<App />, document.getElementById("root"));
