import React, { useState } from "react";
import { observer } from "mobx-react";
import { groupBy } from "lodash";
import { format, parseISO } from "date-fns";
import numeral from "numeral";
import { FiChevronRight } from "react-icons/fi";
import { ENV } from "../../../config";
import useModal from "../../../hooks/useModal";
import sb from "../../../assets/sb.webp";
import lpm from "../../../assets/lpm.webp";
import presto from "../../../assets/presto.webp";
import EnterRegistrationCodeModalBody from "./EnterRegistrationCodeModal";
import "./TeacherListing.scss";

const curriculumLogos = { "Sound Beginnings": sb, "Let's Play Music": lpm, Presto: presto };
const courseSortOrder = {
  "01FHKHHY91XA26BD3FX6EAASJ8": 0,
  "01FHKHHY91HYE106DGKHVQ1N30": 1,
  "01FHKHHY91ZAGKJ0AVN922NWPN": 2,
  "01FHKHHY91VVXM9RGSSFHSMYAS": 3,
  "01FHKHHY91F2VZ47T4E7QCYCHC": 4,
  "01FHKHHY91RP5M3BX0N475KE7P": 5,
  "01FHKHHY918JHDXGK4GE81KE8E": 6,
  "01FHKHHY91HF16BN79CYX2E236": 7,
  "01FHKHHY918W6RQ75D7CDXRDVR": 8,
  "01FHKHHY91EJV47PT40EVXJTAW": 9,
  "01FHKHHY91PVVR87YBANB35H83": 10,
  "01FHKHHY918EBX64K2W5TE4H3P": 11
};
const coursesWithNoSampleClass = [
  "01FHKHHY918W6RQ75D7CDXRDVR",
  "01FHKHHY91EJV47PT40EVXJTAW",
  "01FHKHHY918EBX64K2W5TE4H3P"
];

const sortClassesByDayAndTime = (a, b) => {
  if (a?.dayOfWeek === b?.dayOfWeek) return a?.sessionTime > b?.sessionTime ? 1 : -1;
  return a?.dayOfWeek > b?.dayOfWeek ? 1 : -1;
};

const ClassBox = ({ class: c, selectClass }) => {
  const ingEd = parseISO(c?.startDate) < new Date() ? "ed " : "ing ";
  const start = "Start" + ingEd + format(parseISO(c?.startDate), "MMM do, yyyy");
  const curriculumClass = c?.course?.curriculum?.title?.replace(/[^A-Za-z]/g, "")?.toLowerCase();

  return (
    <div
      className={`class-item ${curriculumClass}${c?.seatsAvailable ? "" : " disabled"}`}
      key={c?.sessionTime + "-" + c?.period}
      onClick={selectClass(c)}
    >
      <div className="class-item-contents">
        <div className="right">
          <div className="session-time">{c.sessionTime}</div>
          <div className="session-period">{start}</div>
        </div>
        <div className="left">
          <div className="seats-available">
            {c?.seatsAvailable || "No"} seat{c?.seatsAvailable !== 1 ? "s" : ""} available
          </div>
          {/* <Button type="cta">Enroll In This Class</Button> */}
        </div>
      </div>
    </div>
  );
};

const CourseSection = ({ course, teacher, classes, selectClass, openSampleClass, defaultToOpen }) => {
  const [collapsed, setCollapsed] = useState(!defaultToOpen);
  const toggleCollapsed = () => setCollapsed(!collapsed);

  const openSampleClassForCourse = () => openSampleClass(teacher, course);

  const courseTitle =
    course?.curriculum?.title === "Sound Beginnings" ? course?.name : course.curriculum.title + ": " + course?.name;
  const classItems = classes
    ?.sort(sortClassesByDayAndTime)
    ?.map(c => <ClassBox {...{ class: c, selectClass, code: teacher?.infusionsoftId }} key={c?.id || c?.classId} />)
    ?.filter(Boolean);

  const sampleClassButton = coursesWithNoSampleClass?.includes(course?.id) ? null : (
    <div className="sample-class-button" onClick={openSampleClassForCourse}>
      <span>Try a Sample Class</span>
    </div>
  );

  return (
    <div className={`curriculum-section${collapsed ? " collapsed" : ""}`}>
      <div className="title-row">
        <div className="left-items">
          <img
            className="curriculum-logo"
            src={`https://${ENV}-lpm-assets.b-cdn.net/icons/${course?.id}`}
            alt="Logo for this course"
          />
          <div className="left-titles">
            <div className="course-title">{courseTitle}</div>
            {sampleClassButton}
          </div>
        </div>
        <div className="right-items" onClick={toggleCollapsed}>
          {collapsed ? "See" : "Hide"} {classItems?.length} class{classItems?.length === 1 ? "" : "es"}
          <FiChevronRight size={22} className="collapse-link-icon" />
        </div>
      </div>
      <div className={`classes-list${collapsed ? " collapsed" : ""}`}>{classItems}</div>
    </div>
  );
};

const CoursesSection = ({ teacher, classes, defaultToOpen, actions }) => {
  const { validateCodeOrSelectClass, openSampleClass } = actions || {};

  const courseLookup = classes
    ?.map(c => c?.course)
    ?.reduce((acc, next) => {
      acc[next?.id] = next;
      return acc;
    }, {});
  const classesByCourse = groupBy(classes || [], "course.id");

  const courseEntries = Object.entries(classesByCourse);
  const courseSections =
    courseEntries?.length > 0 ? (
      courseEntries
        // ?.filter(([_, classes]) => classes?.map(c => c?.seatsAvailable)?.reduce((acc, next) => acc + next, 0) > 0)
        ?.sort((a, b) => courseSortOrder[a?.[0]] - courseSortOrder[b?.[0]])
        ?.map(([courseId, classes]) => (
          <CourseSection
            {...{
              teacher,
              course: courseLookup[courseId],
              classes,
              selectClass: validateCodeOrSelectClass,
              openSampleClass,
              defaultToOpen
            }}
            key={courseId}
          />
        ))
    ) : (
      <div className="no-classes-zero-state">
        Looks like this teacher isn't offering any classes for registration right now.
      </div>
    );

  return courseSections;
};

const LocationSection = ({ teacher, classes, defaultToOpen, actions }) => {
  const locationName = classes?.[0]?.location?.name;

  return (
    <div className="location-group-wrapper">
      <div className="location-name">{locationName}</div>
      <CoursesSection {...{ teacher, classes, defaultToOpen, actions }} />
    </div>
  );
};

const ClassListings = ({ teacher, classes, defaultToOpen, actions }) => {
  const locationClassGroups = Object.values(groupBy(classes, "location.geohash"));
  if (locationClassGroups?.length > 1) {
    const locationSections = locationClassGroups?.map((_classes, i) => {
      return <LocationSection {...{ teacher, classes: _classes, defaultToOpen, actions }} key={i} />;
    });
    return locationSections;
  }

  return <CoursesSection {...{ teacher, classes, defaultToOpen, actions }} />;
};

const TeacherListing = ({
  teacher,
  openBio,
  openTestimonials,
  openSampleClass,
  startEnrollmentInClass,
  defaultToOpen = false
}) => {
  const {
    infusionsoftId,
    profilePicture,
    firstName,
    lastName,
    email: emails,
    distance,
    classes,
    primaryCurriculum
  } = teacher || {};
  const email = emails?.[0]?.email;

  const [profilePictureIsValid, setProfilePictureIsValid] = useState(true);
  const markProfilePictureInvalid = () => setProfilePictureIsValid(false);

  const selectClass = classToSelect => () => {
    if (classToSelect?.seatsAvailable > 0) startEnrollmentInClass(teacher, classToSelect);
  };

  const codeToMatch = infusionsoftId;
  const { Modal: EnterRegistrationCodeModal, openModal: openEnterRegistrationCodeModal } = useModal(
    EnterRegistrationCodeModalBody,
    { selectClass, codeToMatch },
    { portalElementId: "enter-registration-code-modal" }
  );

  const openTeacherBio = () => openBio(teacher);
  const openTeacherTestimonials = () => openTestimonials(teacher);

  const validateCodeOrSelectClass = classToSelect => () => {
    if (classToSelect?.registrationCodeRequired) openEnterRegistrationCodeModal({ class: classToSelect });
    else selectClass(classToSelect)();
  };

  const locationStrings = [...new Set(classes?.map(c => `${c?.location?.locality}, ${c?.location?.region}`))];
  let locationsLabel = locationStrings?.[0];
  if (locationStrings?.length === 2) {
    locationsLabel = locationStrings?.join(" and ");
  } else if (locationStrings?.length === 2) {
    locationsLabel = locationStrings?.slice(0, -1)?.join(", ") + ", and " + locationStrings?.at(-1);
  }
  const studioLocation = locationStrings?.length ? <span className="studio-location">{locationsLabel}</span> : null;
  const distanceFromMeLabel = distance ? <span>({numeral(distance).format("0,00.0")} miles away)</span> : null;
  const contactButton = email ? (
    <a className="contact-button" href={`mailto:${email}`}>
      Contact Teacher
    </a>
  ) : null;
  // const backgroundCheckLabel = FBIClearance ? (
  //   <div className="background-check">
  //     <FiCheck className="background-check-check" /> FBI Background Checked
  //   </div>
  // ) : null;

  const link = teacher?.website || teacher?.blog;
  const siteLink = link ? (
    <a href={link} className="link" target="_blank" rel="noreferrer">
      Website
    </a>
  ) : null;

  const bioLink = teacher?.bio ? (
    <span className="link" onClick={openTeacherBio}>
      Bio
    </span>
  ) : null;
  const testimonialsLink = teacher?.testimonials ? (
    <span className="link" onClick={openTeacherTestimonials}>
      Testimonials
    </span>
  ) : null;

  const videoLink = teacher?.video ? (
    <a href={teacher.video} className="link" target="_blank" rel="noreferrer">
      Video
    </a>
  ) : null;

  const classListingActions = { validateCodeOrSelectClass, openSampleClass };

  return (
    <div className="teacher-listing">
      <div className="teacher-listing-content">
        <div className="top-row">
          <div className="profile-picture-wrapper">
            <img
              className="profile-picture"
              src={profilePictureIsValid ? profilePicture : curriculumLogos[primaryCurriculum]}
              alt="Teacher's profile headshot"
              onError={markProfilePictureInvalid}
            />
          </div>
          <div className="teacher-info">
            <div className="teacher-name">
              {firstName.toProperCase()} {lastName.toProperCase()}
            </div>
            <div className="distance">
              {studioLocation}
              {distanceFromMeLabel}
            </div>
            {contactButton}
            {/* {backgroundCheckLabel} */}
            <div className="links">
              {bioLink}
              {testimonialsLink}
              {siteLink}
              {videoLink}
            </div>
          </div>
        </div>
        <div className="class-listings-wrapper">
          <ClassListings {...{ teacher, classes, defaultToOpen, actions: classListingActions }} />
        </div>
      </div>
      <EnterRegistrationCodeModal />
    </div>
  );
};

export default observer(TeacherListing);
