import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import AuthStore from "../../stores/AuthStore";
import UserStore from "../../stores/UserStore";
import CartStore from "../../stores/CartStore";
import MaterialsStore, {
  filterOutShippingProducts,
  filterOutLateFeeProducts,
  filterOutTaxProducts
} from "../../stores/MaterialsStore";
import useCardValid from "./useCardValid";
import EnrollmentItem from "./EnrollmentItem";
import LateFeeItem from "./LateFeeItem";
import ShippingItem from "./ShippingItem";
import AZTaxItem from "./AZTaxItem";
import PaymentInfoSection, { NEW_CARD_OPTION_LABEL } from "./PaymentInfoSection";
import CheckoutButton from "./CheckoutButton";
import "./Checkout.scss";
import CouponItem from "./CouponItem";

const Checkout = observer(() => {
  const { isMobile } = useBreakpoints();

  const { authDetermined, authenticated } = AuthStore || {};
  const { enrollmentsReadyForCheckout } = CartStore || {};

  const hasEnrollmentsForCheckout = !!enrollmentsReadyForCheckout?.length;
  useEffect(() => {
    if (authDetermined) {
      if (!authenticated || !hasEnrollmentsForCheckout) navigate("/");
    }
  }, [authDetermined, authenticated, hasEnrollmentsForCheckout]);

  const { rawCards, cards } = UserStore || {};
  useEffect(() => {
    if (!rawCards) UserStore?.fetchCardsForUser();
  }, [rawCards]);

  const enrollmentCourseIds = enrollmentsReadyForCheckout?.map(({ class: classObject }) => classObject?.courseId);
  useEffect(() => {
    if (enrollmentCourseIds?.length) {
      enrollmentCourseIds.forEach(courseId => MaterialsStore.fetchMaterialsForCourse(courseId));
    }
  }, [enrollmentCourseIds]);

  const [selectedCard, setSelectedCard] = useState();
  const [enteringNewCard, setEnteringNewCard] = useState(false);
  const [newCardDetails, setNewCardDetails] = useState({});
  const cardValid = useCardValid({ cardDetails: newCardDetails, setCardDetails: setNewCardDetails });
  const [processing, setProcessing] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const updateSelectedCard = selectedCardLabel => {
    if (selectedCardLabel === NEW_CARD_OPTION_LABEL) {
      setSelectedCard(NEW_CARD_OPTION_LABEL);
      setEnteringNewCard(true);
    } else {
      setSelectedCard(cards?.find(c => c?.label === selectedCardLabel));
    }
  };

  const hasProductsToShip = !!CartStore?.productItemsReadyForCheckout
    ?.filter(filterOutShippingProducts)
    ?.filter(filterOutLateFeeProducts)
    ?.filter(filterOutTaxProducts)?.length;
  let checkoutEnabled = false;
  if (!hasProductsToShip) checkoutEnabled = true;
  if (selectedCard === NEW_CARD_OPTION_LABEL ? cardValid : !!selectedCard) checkoutEnabled = true;
  const completePurchase = async () => {
    if (checkoutEnabled) {
      setProcessing(true);
      let card = selectedCard;
      if (selectedCard === NEW_CARD_OPTION_LABEL && cardValid) {
        card = await UserStore?.createCard(newCardDetails);
        if (!card) {
          setCheckoutError(
            "Looks like we had an issue adding your card. Try refreshing the page, and if you still don't see your card in the list, please contact our office."
          );
          return;
        }
      }

      const { success, thankYouParams, error } = await CartStore?.checkOut(card);
      if (success) {
        let params;
        try {
          params = btoa(JSON.stringify(thankYouParams));
        } catch {}
        navigate(`/thankyou${params ? `?p=${params}` : ""}`);
        setTimeout(() => CartStore.clearEnrollmentsAfterCheckOut(), 100);
      } else {
        setCheckoutError(error);
      }

      setProcessing(false);
    }
  };

  const enrollmentItems = CartStore?.enrollmentsReadyForCheckout?.map(enrollment => (
    <EnrollmentItem {...enrollment} key={enrollment.id} />
  ));

  const actionButtonStyles = enteringNewCard ? null : { marginTop: 48 };
  const actionButtonDisabled = !checkoutEnabled || processing;
  const actionButton = (
    <>
      <CheckoutButton
        style={actionButtonStyles}
        action={completePurchase}
        disabled={actionButtonDisabled}
        processing={processing}
        hasProductsToShip={hasProductsToShip}
      />
      <div className="terms-and-conditions-disclaimer">
        By completing your purchase, you agree to the following{" "}
        <a
          className="terms-and-conditions-link"
          href="https://www.new.letsplaymusicsite.com/enrollmentdisclaimer"
          target="_blank"
          rel="noopener noreferrer"
        >
          terms and conditions.
        </a>
      </div>
      <div className="materials-reminder">
        As a reminder, your materials will be delivered to your teacher who will distribute them to you at the beginning
        of your class.
      </div>
    </>
  );

  const paymentInfoProps = {
    selectedCard,
    updateSelectedCard,
    enteringNewCard,
    setEnteringNewCard,
    newCardDetails,
    setNewCardDetails,
    actionButton,
    checkoutError
  };
  const paymentInfoSection = <PaymentInfoSection {...{ ...paymentInfoProps, hasProductsToShip }} />;

  const lateFeesItem = CartStore?.lateFeeTotal ? <LateFeeItem /> : null;
  const shippingItem = hasProductsToShip ? <ShippingItem /> : null;
  const taxItem = CartStore?.AZTaxPercentage ? <AZTaxItem /> : null;

  const couponItem = CartStore?.enrollmentsReadyForCheckout?.length ? <CouponItem /> : null;

  const headerLabel = hasProductsToShip ? "What I'm Purchasing" : "What I'm Enrolling In";

  return (
    <div className="checkout">
      {isMobile ? paymentInfoSection : null}
      <div className="col">
        <div className="col-header">{headerLabel}</div>
        <div className="enrollment-items-wrapper">
          {enrollmentItems}
          {shippingItem}
          {lateFeesItem}
          {taxItem}
          {couponItem}
        </div>
      </div>
      {isMobile ? null : paymentInfoSection}
      {isMobile ? actionButton : null}
    </div>
  );
});

export default Checkout;
