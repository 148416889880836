import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import useLocation from "../../../hooks/useLocation";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import ClassesStore from "../../../stores/ClassesStore";
import CartStore from "../../../stores/CartStore";
import AuthStore from "../../../stores/AuthStore";
import { Spinner, Button, Input, TeacherListing } from "../../-common";
import ClassMap from "./ClassMap";
import "./GeoSearch.scss";

const GeoSearch = observer(({ openTeacherBio, openTeacherTestimonials, openSampleClassForTeacher }) => {
  const { isMobile } = useBreakpoints();

  const {
    loading: loadingSearchResults,
    searchResults,
    totalClasses,
    totalTeachers,
    totalLocations
  } = ClassesStore || {};

  const [view, setView] = useState("map");
  const toggleView = () => setView(view === "map" ? "list" : "map");

  const { loading: loadingLocation, location, locate, manuallySetLocation } = useLocation();
  const { lat, lng } = location || {};

  const [center, setCenter] = useState({});
  useEffect(() => {
    if (lat != null && lng != null) {
      (async () => {
        setCenter({ lat, lng });
        await ClassesStore.searchByGeo({ latitude: lat, longitude: lng });
      })();
    }
  }, [lat, lng]);

  const [zip, setZip] = useState("");
  const updateZip = async ({ target }) => {
    const newZip = target.value?.replace(/[^\d]/g, "")?.slice(0, 5);
    setZip(newZip);

    if (newZip?.length === 5) {
      const { latitude, longitude } = (await ClassesStore.searchByGeo({ zipcode: newZip })) || {};
      if (latitude != null && longitude != null) {
        setCenter({ lat: latitude, lng: longitude });
        manuallySetLocation({ lat: latitude, lng: longitude });
      }
    }
  };

  const startEnrollmentInClass = (teacher, selectedClass) => {
    CartStore.addEnrollmentToCart({ teacher, class: selectedClass });
    if (!AuthStore?.authenticated) navigate("/auth");
    else navigate("/students");
  };

  const viewToggleLabel = view === "map" ? "View as List" : "See on Map";

  const mapLoading = loadingLocation || loadingSearchResults;
  const mapLoadingOverlay = mapLoading ? (
    <div className="map-loading-overlay">
      <Spinner />
    </div>
  ) : null;

  const noResultsOverlay =
    !mapLoading && !searchResults?.length ? (
      <div className="map-loading-overlay passthrough">
        <div className="no-results-message">
          No results found... Try another zipcode and if you still can't find anything, use the "I Couldn't Find A
          Teacher Near Me" button.
        </div>
      </div>
    ) : null;

  const teacherItems = searchResults.map(teacher => (
    <TeacherListing
      {...{
        teacher,
        openBio: openTeacherBio,
        openTestimonials: openTeacherTestimonials,
        openSampleClass: openSampleClassForTeacher,
        startEnrollmentInClass
      }}
      key={teacher.id}
    />
  ));

  const resultsContent =
    view === "map" ? (
      <div className="map-container">
        <ClassMap center={center} />
        {mapLoadingOverlay}
        {noResultsOverlay}
      </div>
    ) : (
      <div className="list-container">{teacherItems}</div>
    );

  const showResultsHeaders = totalClasses || totalTeachers || totalLocations;
  const totalResultsLabel = showResultsHeaders ? (
    <>
      <span>
        {totalClasses} class{totalClasses > 1 ? "es " : " "}
      </span>
      <span>
        from {totalTeachers} teacher{totalTeachers > 1 ? "s " : " "}
      </span>
      <span>
        at {totalLocations} location{totalLocations > 1 ? "s " : ""}
      </span>
    </>
  ) : null;
  const viewAsButton = showResultsHeaders ? (
    <div className="toggle-view" onClick={toggleView}>
      {viewToggleLabel}
    </div>
  ) : null;

  const noResultsButton = (
    <Button
      linkClassName="couldnt-find-link"
      link="https://playmusic.infusionsoft.com/app/form/failed-find-a-teacher-search"
      rel="noreferrer"
      target="_blank"
    >
      I couldn't find a teacher near me
    </Button>
  );

  return (
    <div className="geo-search-wrapper">
      <div className="left-col">
        <h1 className="hero-text">Let's find a teacher near you!</h1>
        <p className="helper-text">
          Our classes are best in-person, and we've got teachers all over the country. If you give us permission to view
          your location, we can show you the teachers closest to where you're at; otherwise we can use a zipcode and do
          the best we can.
        </p>
        <div className="buttons-row">
          <div className="cta-button-wrapper">
            <Button className="location-button" type="cta" action={locate}>
              Use My Location
            </Button>
          </div>
          <span className="or">OR</span>
          <Input
            placeholder="Enter Your Zipcode"
            value={zip}
            onChange={updateZip}
            pattern="[0-9]*"
            inputMode="numeric"
            autoComplete="postal-code"
          />
        </div>
        {isMobile ? null : noResultsButton}
      </div>
      <div className={`right-col${view === "list" ? " list" : ""}`}>
        <div className="results-header-row">
          <div className="total-results">{totalResultsLabel}</div>
          {viewAsButton}
        </div>
        {resultsContent}
        {isMobile ? noResultsButton : null}
      </div>
    </div>
  );
});

export default GeoSearch;
