import { useState } from "react";
import useModal from "./useModal";
import TeacherBioModalBody from "../components/-common/TeacherBioModal";
import TeacherTestimonialsModalBody from "../components/-common/TeacherTestimonialsModal";
import SampleClassModalBody from "../components/-common/SampleClassModal";

function useTeacherListingModals(isMobile) {
  const [teacherForModal, setTeacherForModal] = useState();
  const [courseForModal, setCourseForModal] = useState();

  const { Modal: TeacherBioModal, openModal: openTeacherBioModal } = useModal(
    TeacherBioModalBody,
    { teacher: teacherForModal },
    { portalElementId: "teacher-bio-modal", big: isMobile }
  );

  const setTeacherAndOpenBioModal = teacher => {
    setTeacherForModal(teacher);
    openTeacherBioModal();
  };

  const { Modal: TeacherTestimonialsModal, openModal: openTeacherTestimonialsModal } = useModal(
    TeacherTestimonialsModalBody,
    { teacher: teacherForModal },
    { portalElementId: "teacher-testimonials-modal", big: true }
  );

  const setTeacherAndOpenTestimonialsModal = teacher => {
    setTeacherForModal(teacher);

    openTeacherTestimonialsModal();
  };

  const { Modal: SampleClassModal, openModal: openSampleClassModal } = useModal(
    SampleClassModalBody,
    { teacher: teacherForModal, course: courseForModal },
    { portalElementId: "sample-class-modal" }
  );

  const setTeacherAndOpenSampleClassModal = (teacher, course) => {
    setTeacherForModal(teacher);
    setCourseForModal(course);
    openSampleClassModal();
  };

  return {
    teacherForModal,
    courseForModal,
    TeacherBioModal,
    setTeacherAndOpenBioModal,
    TeacherTestimonialsModal,
    setTeacherAndOpenTestimonialsModal,
    SampleClassModal,
    setTeacherAndOpenSampleClassModal
  };
}

export default useTeacherListingModals;
