import React, { useRef } from "react";
import Spinner from "../Spinner";
import "./FullScreenModal.scss";

function FullScreenModal({ big, close, loading, isOpen, children }) {
  const backgroundRef = useRef();

  const loadingOverlay = loading ? (
    <div className="loading-overlay">
      <Spinner size={256} />
    </div>
  ) : null;

  const backgroundClose = e => {
    if (e.target === backgroundRef.current) {
      close();
    }
  };

  return (
    <div
      className={`fullscreen-modal${isOpen ? " open" : ""}${big ? " big" : ""}`}
      ref={backgroundRef}
      onClick={backgroundClose}
    >
      {children}
      {loadingOverlay}
    </div>
  );
}

export default FullScreenModal;
