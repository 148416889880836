import React from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import levelup from "../../assets/levelup.png";
import parent from "../../assets/parent.png";
import search from "../../assets/search.png";
import AuthStore from "../../stores/AuthStore";
import { Button } from "../-common";
import "./Personas.scss";

const Personas = observer(() => {
  const goToGeoSearch = () => navigate("/search");

  const goToListSearch = () => navigate("/search?list=true");

  const goToNextCourse = () => {
    if (AuthStore?.authDetermined) {
      if (AuthStore?.authenticated) {
        navigate("/student?nextcourse=true");
      } else {
        navigate("/auth?nextcourse=true");
      }
    }
  };

  return (
    <div className="personas-wrapper">
      <div className="title">Let's help you find a teacher</div>
      <div className="persona-squares-row">
        <div className="persona-square-wrapper">
          <div className="persona-square" onClick={goToGeoSearch}>
            <div className="persona-icon-wrapper">
              <img className="persona-studio-image" src={search} alt="Stylistic icon for browse teachers option" />
            </div>
            <div className="persona-title">I want to browse teachers and classes</div>
            <div style={{ flex: 1 }} />
            <div className="persona-description">Click here if you want to find the classes nearest you.</div>
            <div style={{ flex: 1 }} />
            <Button type="cta">Browse Classes</Button>
          </div>
        </div>
        <div className="persona-square-wrapper">
          <div className="persona-square" onClick={goToListSearch}>
            <div className="persona-icon-wrapper">
              <img className="persona-studio-image" src={parent} alt="Stylistic icon for browse teachers option" />
            </div>
            <div className="persona-title">I'm looking for a specific teacher</div>
            <div className="persona-description">
              Click here if you know your teacher's name and you just need to find their class listings to get enrolled.
            </div>
            <div style={{ flex: 1 }} />
            <Button type="cta">Search Teachers</Button>
          </div>
        </div>
        <div className="persona-square-wrapper">
          <div className="persona-square" onClick={goToNextCourse}>
            <div className="persona-icon-wrapper">
              <img className="persona-studio-image" src={levelup} alt="Stylistic icon for browse teachers option" />
            </div>
            <div className="persona-title">I want to enroll in my child's next level course</div>
            <div className="persona-description">
              Click here if your child has already been enrolled and you want to get them into the next level as easily
              as possible.
            </div>
            <div style={{ flex: 1 }} />
            <Button type="cta">Enroll in Next Level</Button>
          </div>
        </div>
      </div>
      <div className="not-sure-row">
        <Button
          className="not-sure-link"
          link="https://www.new.letsplaymusicsite.com/our-classes"
          rel="noreferrer"
          target="_blank"
        >
          I'm not sure which program to enroll in
        </Button>
        <Button
          className="not-sure-link"
          link="https://playmusic.infusionsoft.com/app/form/failed-find-a-teacher-search"
          rel="noreferrer"
          target="_blank"
        >
          I couldn't find a teacher near me
        </Button>
      </div>
    </div>
  );
});

export default Personas;
