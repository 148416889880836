import React, { useState } from "react";
import { observer } from "mobx-react";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { FiGift, FiMail, FiPhone, FiPlus, FiUser, FiX } from "react-icons/fi";
import ClassesStore from "../../../stores/ClassesStore";
import { Button, Input, Spinner } from "..";
import "./SampleClassModal.scss";

const MainContent = ({ teacher, course, setRequestSent, close }) => {
  const [name, setName] = useState("");
  const updateName = ({ target }) => setName(target.value);

  const [email, setEmail] = useState("");
  const updateEmail = ({ target }) => setEmail(target.value);

  const [phone, setPhone] = useState("");
  const updatePhone = ({ target }) => {
    if (!isNaN(Number(phone.slice(-1)))) {
      const phoneFormatter = new AsYouType("US");
      const phone = phoneFormatter.input(target.value);
      setPhone(phone);
    } else {
      setPhone(target.value);
    }
  };

  const [childAges, setChildAges] = useState([""]);
  const addChildAge = () => setChildAges(childAges?.concat(""));
  const removeChildAge = i => () => setChildAges(childAges?.slice(0, i)?.concat(childAges?.slice(i + 1)));
  const updateChildAge = i => ({ target }) => {
    if (childAges?.[i] != null) {
      const newChildAges = childAges
        ?.slice(0, i)
        ?.concat(target?.value)
        ?.concat(childAges?.slice(i + 1));
      setChildAges(newChildAges);
    }
  };

  const [loading, setLoading] = useState(false);

  let phoneIsValid = false;
  try {
    phoneIsValid = parsePhoneNumber(phone, "US").isValid();
  } catch {}
  const sendRequestEnabled = !!name && !!email && !!phoneIsValid && childAges?.every(Number) && childAges?.length > 0;

  const sendRequest = async () => {
    if (sendRequestEnabled) {
      setLoading(true);
      const formattedPhone = parsePhoneNumber(phone, "US").format("E.164");
      const success = await ClassesStore?.sendSampleClassRequest({
        teacherId: teacher?.id,
        courseId: course?.id,
        name,
        email,
        phoneNumber: formattedPhone,
        children: childAges?.map(Number)
      });
      setLoading(false);

      if (success) setRequestSent(true);
    }
  };

  const childRows = childAges?.map((age, i) => (
    <div className="child-row">
      <div className="child-number">Child #{i + 1}'s Age:</div>
      <Input
        icon={FiGift}
        placeholder="ex. 7"
        value={age}
        onChange={updateChildAge(i)}
        type="text"
        pattern="[0-9]*"
        inputMode="numeric"
      />
      <FiX
        className={`remove-child-icon${!i ? " disabled" : ""}`}
        size={64}
        color={!i ? "var(--white)" : "var(--medium-gray)"}
        onClick={!i ? null : removeChildAge(i)}
      />
    </div>
  ));

  const sendButtonContent = loading ? <Spinner /> : "Send Request";

  return (
    <>
      <div className="content">
        <div className="title">Try a Sample {course?.name} Class</div>
        <div className="input-wrapper">
          <div className="input-title">Your Name</div>
          <Input
            type="text"
            icon={FiUser}
            placeholder="ex. Jane Doe"
            value={name}
            onChange={updateName}
            autoComplete="name"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-title">Your Email</div>
          <Input
            type="email"
            icon={FiMail}
            placeholder="ex. janedoe1@gmail.com"
            value={email}
            onChange={updateEmail}
            autoComplete="email"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-title">Your Phone Number</div>
          <Input
            type="tel"
            icon={FiPhone}
            placeholder="ex. 555-123-4567"
            value={phone}
            onChange={updatePhone}
            autoComplete="tel"
          />
        </div>
        <div className="input-wrapper">
          <div className="input-title">Children</div>
          {childRows}
          <div className="add-child-button-row">
            <div className="add-child-button" onClick={addChildAge}>
              <FiPlus />
              <span>Add Another Child</span>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className={`send-request-button${loading ? " button-loading" : ""}`}
            action={sendRequest}
            disabled={!sendRequestEnabled}
          >
            {sendButtonContent}
          </Button>
        </div>
      </div>
    </>
  );
};

const RequestSentContent = ({ teacher, course, close }) => (
  <>
    <div className="content">
      <div className="title">Try a Sample {course?.name} Class</div>
      <div className="message-wrapper">
        <div className="message">
          Thanks! We've sent an email to {teacher?.firstName} with your information - look for a response soon.
        </div>
      </div>
    </div>
    <div className="bottom-buttons">
      <div className="left-buttons" />
      <div className="right-buttons">
        <Button type="primary" action={close}>
          CLOSE
        </Button>
      </div>
    </div>
  </>
);

function SampleClassModal({ teacher, course, close }) {
  const [requestSent, setRequestSent] = useState(false);

  const content = requestSent ? (
    <RequestSentContent {...{ teacher, course, close }} />
  ) : (
    <MainContent {...{ teacher, course, setRequestSent, close }} />
  );

  return <div className="sample-class-modal">{content}</div>;
}

export default observer(SampleClassModal);
