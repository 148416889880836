import React from "react";
import { observer } from "mobx-react";
import numeral from "numeral";
import { useBreakpoints } from "../../../hooks/useBreakpoints";
import CartStore from "../../../stores/CartStore";
import "./LateFeeItem.scss";

const LateFeeItem = () => {
  const { isMobile } = useBreakpoints();

  const lateFeeTotal = CartStore?.lateFeeTotal;
  const formattedTotal = numeral(lateFeeTotal / 100).format("$0,0.00");
  const label = "Late Fee - " + formattedTotal;

  const totalLabel =
    lateFeeTotal != null ? (
      <div className="total">
        {isMobile ? "TOTAL: " : ""}
        {formattedTotal}
      </div>
    ) : null;

  return (
    <div className="checkout-late-fees-item">
      <div className="late-fees-item-title">Late Fee</div>
      <div className="late-fee-line">{label}</div>
      <div className="bottom-row">{totalLabel}</div>
      <div className="why-late-fee">
        <div className="why-title">Why do I have a late fee?</div>
        <div className="why-explanation">
          Looks like your teacher’s class materials have already been processed. We’ll process your enrollment materials
          individually and send them out as soon as we can.
        </div>
      </div>
    </div>
  );
};

export default observer(LateFeeItem);
