import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { navigate } from "@reach/router";
import useQueryParams from "../../../hooks/useQueryParams";
import "./ResetPasswordSuccess.scss";

const ResetPasswordSuccess = observer(() => {
  const { nextcourse } = useQueryParams();

  useEffect(() => {
    setTimeout(() => {
      if (nextcourse) navigate("/student?nextcourse=true");
      else navigate("/students");
    }, 5000);
  }, [nextcourse]);

  return (
    <div className="reset-password-success">
      <div className="left-col">
        <h1 className="hero-text">Password reset successfully!</h1>
        <p className="helper-text">
          You'll use the new one from here on out. Hang around for a few seconds and we'll log you in automatically.
        </p>
      </div>
    </div>
  );
});

export default ResetPasswordSuccess;
