import { makeAutoObservable } from "mobx";
import { request } from "../utils";

export const filterOutShippingProducts = p => !p?.name?.toLowerCase()?.includes("shipping");
export const filterToOnlyShippingProducts = p => p?.name?.toLowerCase()?.includes("shipping");

export const filterOutLateFeeProducts = p => !p?.name?.toLowerCase()?.includes("fee - processing fee");
export const filterToOnlyLateFeeProducts = p => p?.name?.toLowerCase()?.includes("fee - processing fee");

export const filterOutTaxProducts = p => !p?.name?.toLowerCase()?.includes("tax");
export const filterToOnlyTaxProducts = p => p?.name?.toLowerCase()?.includes("tax");

class MaterialsStore {
  constructor() {
    makeAutoObservable(this);
  }

  loading = false;

  rawMaterialsForCourses = {};

  get materialsByCourseId() {
    return Object.fromEntries(
      Object.entries(this.rawMaterialsForCourses)?.map(([courseId, materials]) => {
        const products = materials?.products
          ?.filter(filterOutShippingProducts)
          ?.filter(filterOutLateFeeProducts)
          ?.filter(filterOutTaxProducts)
          ?.sort((a, b) => (a?.order < b?.order ? -1 : 1));
        const bundles = materials?.bundles?.map(b => {
          const productList = b?.productList
            ?.filter(filterOutShippingProducts)
            ?.filter(filterOutLateFeeProducts)
            ?.filter(filterOutTaxProducts);
          const productNames = productList?.map(p => "1x " + p?.name).join(", ");
          const price = productList?.map(p => p?.price)?.reduce((acc, next) => acc + next, 0);
          return { ...b, productList, productNames, price };
        });
        return [courseId, { ...materials, products, bundles }];
      })
    );
  }

  get shippingCostsByCourseId() {
    const shippingCostsByCourseEntries = Object.entries(this.rawMaterialsForCourses)?.map(([courseId, materials]) => {
      const shippingForCourse = materials?.products?.filter(filterToOnlyShippingProducts)?.[0];
      return [courseId, shippingForCourse];
    });
    return Object.fromEntries(shippingCostsByCourseEntries);
  }

  get lateFeesByCourseId() {
    const lateFeeByCourseEntries = Object.entries(this.rawMaterialsForCourses)?.map(([courseId, materials]) => {
      const lateFeeForCourse = materials?.products?.filter(filterToOnlyLateFeeProducts)?.[0];
      return [courseId, lateFeeForCourse];
    });
    return Object.fromEntries(lateFeeByCourseEntries);
  }

  get arizonaSalesTaxByCourseId() {
    const azTaxByCourseEntries = Object.entries(this.rawMaterialsForCourses)?.map(([courseId, materials]) => {
      const taxForCourse = materials?.products?.filter(filterToOnlyTaxProducts)?.[0];
      return [courseId, taxForCourse];
    });
    return Object.fromEntries(azTaxByCourseEntries);
  }

  inFlightMaterialsRequests = {};
  async fetchMaterialsForCourse(courseId) {
    if (this.rawMaterialsForCourses?.[courseId] || this.inFlightMaterialsRequests?.[courseId]) return;

    this.loading = true;
    try {
      const courseMaterialsRequest = request.get(`/courses/${courseId}/materials`);
      this.inFlightMaterialsRequests = { ...this.inFlightMaterialsRequests, [courseId]: courseMaterialsRequest };
      const materials = await courseMaterialsRequest;
      this.rawMaterialsForCourses = { ...this.rawMaterialsForCourses, [courseId]: materials };
      this.inFlightMaterialsRequests = { ...this.inFlightMaterialsRequests, [courseId]: null };
      this.loading = false;
      return materials;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  clear() {
    this.rawMaterialsForCourses = {};
  }
}

export default new MaterialsStore();
