import { makeAutoObservable, autorun } from "mobx";
import { ENV } from "../config";
import { request } from "../utils";
import AuthStore from "./AuthStore";
import CoursesStore from "./CoursesStore";

class UserStore {
  constructor() {
    makeAutoObservable(this);

    autorun(() => {
      if (AuthStore.authenticated && AuthStore.sub) {
        this.fetchUser();
        this.fetchChildrenForUser();
      } else this.clear();
    });
  }

  loading = true;

  rawUser = {};

  get user() {
    if (!this?.rawUser) return null;

    const profilePicture = `https://${ENV}-lpm-assets.b-cdn.net/profiles/${this.rawUser?.id}?m=${this.rawUser?.modified}`;
    return {
      ...this.rawUser,
      id: this.rawUser?.id || AuthStore?.id,
      infusionsoftId: this.rawUser?.infusionsoftId || AuthStore?.infusionsoftId,
      email: this.rawUser?.email?.[0]?.email || AuthStore?.email,
      profilePicture
    };
  }

  get coursesToDate() {
    return this.user?.registrations?.map(r => CoursesStore?.coursesById?.[r?.courseId])?.filter(Boolean);
  }

  loadingChildren = true;

  rawChildren = [];

  get children() {
    return this.rawChildren.map(c => ({
      ...c,
      profilePicture: `https://${ENV}-lpm-assets.b-cdn.net/profiles/${c?.id}?m=${c?.modified}`,
      lastClass: c?.lastClass
        ? {
            ...c.lastClass,
            courseLogo: `https://${ENV}-lpm-assets.b-cdn.net/icons/${c.lastClass?.courseId}?m=${c.lastClass?.modified}`
          }
        : null
    }));
  }

  loadingCards = false;
  creatingCard = false;

  rawCards = null;

  get cards() {
    if (!this?.rawUser) return [];
    return this.rawCards?.map(c => ({
      ...c,
      label: `${c?.card_type} ending in ${c?.card_number?.replace(/x/g, "")}`
    }));
  }

  async fetchUser() {
    this.loading = true;
    try {
      const user = await request.get(`/users/me`);
      this.rawUser = user;
      this.loading = false;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async fetchChildrenForUser() {
    this.loadingChildren = true;
    try {
      const children = await request.get(`/children`);
      this.rawChildren = children;
      this.loadingChildren = false;
    } catch (err) {
      console.warn(err);
      this.loadingChildren = false;
    }
  }

  async fetchCardsForUser() {
    this.loadingCards = true;
    try {
      const cards = await request.get(`/users/me/cards`);
      this.rawCards = cards;
      return cards;
    } catch (err) {
      console.warn(err);
      this.loadingCards = false;
    }
  }

  async createCard({ number, brand, expMonth, expYear, name, cvv }) {
    this.creatingCard = true;
    try {
      const newCard = await request.post(`/users/me/cards`, {
        body: {
          cardNumber: number,
          cardType: brand,
          emailAddress: this.user?.email,
          expirationMonth: expMonth,
          expirationYear: expYear,
          cardName: name,
          verificationCode: cvv
        }
      });
      this.rawCards = this.rawCards?.concat(newCard);
      return newCard;
    } catch (err) {
      console.warn(err);
      this.creatingCard = false;
    }
  }

  async updateUser(updates) {
    if (!this?.user?.id) return;

    this.loading = true;
    try {
      const user = await request.put(`/users/me`, { body: updates });
      this.rawUser = user;
      this.loading = false;
      return user;
    } catch (err) {
      console.warn(err);
      this.loading = false;
    }
  }

  async updateProfilePicture(profilePicture) {
    if (this?.user?.id) {
      try {
        const fileData = await request.get(`/files/profile?userId=${this.user.id}`, {
          headers: { "file-type": profilePicture.type }
        });

        const { url } = fileData || {};
        const uploadResponse = await fetch(url, {
          method: "PUT",
          body: profilePicture,
          headers: { "Content-Type": profilePicture.type, "x-amz-acl": "public-read" }
        });
        if (!uploadResponse.ok) throw new Error("Upload failed.");

        await this.updateUser({ profilePictureUpdated: true });
      } catch (err) {
        console.warn("Error updating profile picture:", err);
        throw err;
      }
    }
  }

  async createChild(studentParams) {
    try {
      const newChild = await request.post(`/accounts/students`, { body: studentParams });
      this.rawChildren = (this.rawChildren || [])?.concat(newChild);
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  clear() {
    this.rawUser = null;
    this.rawChildren = [];
    this.rawCards = null;
  }
}

export default new UserStore();
