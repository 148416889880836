import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiMusic } from "react-icons/fi";
import { Carousel } from "react-responsive-carousel";
import { ENV } from "../../config";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import useQueryParams from "../../hooks/useQueryParams";
import usePlatform from "../../hooks/usePlatform";
import { Button } from "../-common";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./ThankYou.scss";

const devSubdomain = ENV === "dev" ? ".dev" : "";

const RegistrationItem = ({ courseLogo, studentNames, teacherName, period, sessionTime }) => {
  const [logoError, setLogoError] = useState(false);
  const markLogoError = () => setLogoError(true);

  const logoImage =
    !courseLogo || logoError ? (
      <div className={`course-logo placeholder`}>
        <FiMusic size={36} />
      </div>
    ) : (
      <img className="course-logo" src={courseLogo} alt="Course logo" onError={markLogoError} />
    );

  return (
    <div className="registration-item">
      {logoImage}
      <div className="registration-info">
        <div className="bold">{studentNames}</div>
        <div className="aux">{teacherName}</div>
        <div className="aux">{period}</div>
        <div className="aux">{sessionTime}</div>
      </div>
    </div>
  );
};

const ThankYou = observer(() => {
  const { isMobile } = useBreakpoints();
  const { p } = useQueryParams();
  const platform = usePlatform();

  let registrationItemParamSets;
  try {
    registrationItemParamSets = JSON.parse(atob(p));
  } catch {}

  const goToAppDownload = () => {
    let url = "https://play.google.com/store/apps/details?id=com.nascentlabs.letsplaymusic";
    if (platform === "ios") url = "https://apps.apple.com/us/app/lets-play-music/id1556681183";
    window.open(url, "_blank");
  };

  const registrationItems = registrationItemParamSets?.map((r, i) => <RegistrationItem {...r} key={i} />);
  let registrationItemsSection;
  if (registrationItems?.length) {
    if (isMobile) {
      if (registrationItems?.length > 1) {
        registrationItemsSection = (
          <Carousel showThumbs={false} showArrows={false}>
            {registrationItems}
          </Carousel>
        );
      } else {
        registrationItemsSection = <div className="registration-items">{registrationItems}</div>;
      }
    } else {
      if (registrationItems?.length > 3) {
        registrationItemsSection = <Carousel showThumbs={false}>{registrationItems}</Carousel>;
      } else {
        registrationItemsSection = <div className="registration-items">{registrationItems}</div>;
      }
    }
  }

  return (
    <div className="thank-you-wrapper">
      <div className="content">
        <div className="title">Thank you!</div>
        {registrationItemsSection}
        <div className="message">
          You're all set - you should get an emailed receipt really soon. We've also notified your teacher, so look for
          them to reach out near your class start date.
        </div>
        <div className="message">
          From here, you can check out your parent dashboard where you can manage enrollments, or head over to an app
          store and download the official Let's Play Music app!
        </div>
        <div className="buttons-row">
          <Button type="cta" link={`https://dashboard${devSubdomain}.letsplaymusicsite.com`}>
            Go To Dashboard
          </Button>
          <Button type="cta" action={goToAppDownload}>
            Get the App
          </Button>
        </div>

        <div className="materials-reminder">
          As a reminder, your materials will be delivered to your teacher who will distribute them to you at the
          beginning of your class.
        </div>
      </div>
    </div>
  );
});

export default ThankYou;
