import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { navigate } from "@reach/router";
import ClassesStore from "../stores/ClassesStore";

export default function useTeacherSearchResult(teacherId) {
  const { singleTeacherSearchResults } = ClassesStore;
  const [loading, setLoading] = useState(true);
  const [teacherSearchResult, setTeacherSearchResult] = useState();
  useEffect(() => {
    const matchingTeacherSearchResult = singleTeacherSearchResults?.[teacherId];
    if (matchingTeacherSearchResult) {
      setTeacherSearchResult(toJS(matchingTeacherSearchResult));
      setLoading(false);
    } else {
      if (teacherId) {
        (async () => {
          setLoading(true);
          const teacherInfo = await ClassesStore.fetchSingleTeacherSearchResult(teacherId);
          if (!teacherInfo) {
            setLoading(false);
            navigate("/search");
          }
        })();
      }
    }
  }, [singleTeacherSearchResults, teacherId]);
  return { loading, teacher: teacherSearchResult || {} };
}
