import React from "react";
import { observer } from "mobx-react";
import numeral from "numeral";
import CartStore from "../../../stores/CartStore";
import { Button, Spinner } from "../../-common";
import "./CheckoutButton.scss";

const CheckoutButton = ({ style, action, disabled, processing, hasProductsToShip }) => {
  let actionButtonContent = `COMPLETE PURCHASE (${numeral(CartStore?.total / 100).format("$0,00.00")})`;
  if (!hasProductsToShip) actionButtonContent = "COMPLETE ENROLLMENT";
  if (processing) actionButtonContent = <Spinner />;

  return (
    <Button type="cta" className="checkout-button" style={style} action={action} disabled={disabled}>
      {actionButtonContent}
    </Button>
  );
};

export default observer(CheckoutButton);
