import React from "react";
import { observer } from "mobx-react";
import { navigate, Link } from "@reach/router";
import useTeacherSearchResult from "../../hooks/useTeacherSearchResult";
import useQueryParams from "../../hooks/useQueryParams";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import useTeacherListingModals from "../../hooks/useTeacherListingModals";
import ClassesStore from "../../stores/ClassesStore";
import CartStore from "../../stores/CartStore";
import AuthStore from "../../stores/AuthStore";
import { Spinner, TeacherListing } from "../-common";
import "./TeacherDetails.scss";
import { FiChevronLeft } from "react-icons/fi";

const TeacherDetails = observer(({ teacherId }) => {
  const { from, continuingStudentId } = useQueryParams();
  const { isMobile } = useBreakpoints();

  const { loading, teacher } = useTeacherSearchResult(teacherId);

  const {
    TeacherBioModal,
    setTeacherAndOpenBioModal,
    TeacherTestimonialsModal,
    setTeacherAndOpenTestimonialsModal,
    SampleClassModal,
    setTeacherAndOpenSampleClassModal
  } = useTeacherListingModals(isMobile);

  const clearCurrentEnrollment = () => {
    CartStore.removeEnrollmentFromCart(CartStore?.activeEnrollmentId, true);
  };

  const startEnrollmentInClass = (teacher, selectedClass) => {
    if (from === "continue" && continuingStudentId) {
      CartStore.updateEnrollmentInCart(CartStore?.activeEnrollmentId, { teacher, class: selectedClass });
      navigate("/materials");
    } else {
      CartStore.addEnrollmentToCart({ teacher, class: selectedClass });
      if (!AuthStore?.authenticated) navigate("/auth");
      else navigate("/students");
    }
  };

  let backLink = null;
  if (from === "map" && ClassesStore?.searchResults?.length) {
    backLink = (
      <div className="back-link-row">
        <Link to="/search" className="back-link">
          <FiChevronLeft size={24} /> Back to Search
        </Link>
      </div>
    );
  } else if (from === "continue") {
    backLink = (
      <div className="back-link-row double">
        <Link to="/student" className="back-link back" onClick={clearCurrentEnrollment}>
          <FiChevronLeft size={isMobile ? 24 : 16} /> Back to Children
        </Link>
        <Link to="/search" className="back-link" onClick={clearCurrentEnrollment}>
          Don't see the right class? Click here to search other teachers
        </Link>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="teacher-details-wrapper teacher-details-loading">
        {backLink}
        <div className="teacher-details-spinner-wrapper">
          <Spinner alt />
        </div>
      </div>
    );
  }

  const listingProps = {
    teacher,
    openBio: setTeacherAndOpenBioModal,
    openTestimonials: setTeacherAndOpenTestimonialsModal,
    openSampleClass: setTeacherAndOpenSampleClassModal,
    startEnrollmentInClass
  };
  const teacherListing = teacher?.id ? <TeacherListing {...listingProps} defaultToOpen={true} /> : null;

  return (
    <div className="teacher-details-wrapper">
      {backLink}
      {teacherListing}
      <TeacherBioModal />
      <TeacherTestimonialsModal />
      <SampleClassModal />
    </div>
  );
});

export default TeacherDetails;
