import { autorun, makeAutoObservable } from "mobx";
import { ulid } from "ulid";
import groupBy from "lodash.groupby";
import mapValues from "lodash.mapvalues";
import { format } from "date-fns";
import { navigate } from "@reach/router";
import ReactPixel from "react-facebook-pixel";
import { MAZAMA_FACEBOOK_PIXEL_ID } from "../config";
import { request } from "../utils";
import AuthStore from "./AuthStore";
import CoursesStore from "./CoursesStore";
import MaterialsStore from "./MaterialsStore";
import numeral from "numeral";

const STORAGE_KEY = "lpm:enrollment:cart";

ReactPixel.init(MAZAMA_FACEBOOK_PIXEL_ID, null, { autoConfig: true, debug: false });

function getProductsFromEnrollments(enrollments) {
  const allProducts = enrollments?.map(r => r?.products?.filter(p => p?.quantity > 0) || [])?.flat();
  const simplifiedProducts = allProducts?.map(({ id, quantity }) => ({ id, quantity })) || [];

  const allBundles = enrollments?.map(r => Object.values(r?.bundles))?.flat();
  const simplifiedProductsFromBundles =
    allBundles?.map(b => b?.productList?.map(({ id }) => ({ id, quantity: 1 })))?.flat() || [];

  const combinedProductQuantitiesById = simplifiedProducts
    ?.concat(simplifiedProductsFromBundles)
    ?.filter(Boolean)
    ?.reduce((acc, next) => {
      acc[next?.id] = (acc[next?.id] || 0) + next?.quantity;
      return acc;
    }, {});

  return Object.entries(combinedProductQuantitiesById)?.map(([id, quantity]) => ({ id, quantity }));
}

function getThankYouParamsForEnrollments(enrollments) {
  return enrollments?.map(r => {
    const { courseLogo } = CoursesStore?.coursesById?.[r?.class?.courseId] || {};

    const [lastStudent, ...restOfStudents] = (r?.students || [])?.reverse();
    const firstStudentNames = restOfStudents
      ?.map(s => s?.firstName)
      ?.reverse()
      ?.join(", ");
    const lastStudentLabel =
      (!!restOfStudents?.length ? `${r?.students?.length > 2 ? "," : ""} and ` : "") + lastStudent?.firstName;
    const studentNames = !!firstStudentNames || !!lastStudentLabel ? firstStudentNames + lastStudentLabel : "";

    const teacherName = r?.teacher?.firstName + " " + r?.teacher?.lastName;
    const period = r?.class?.period;
    const sessionTime = r?.class?.sessionTime;

    return { courseLogo, studentNames, teacherName, period, sessionTime };
  });
}

class CartStore {
  constructor() {
    makeAutoObservable(this);
    this.initialize();
    autorun(() => {
      if (AuthStore?.authDetermined && !AuthStore?.authenticated) {
        this.clear();
      }
    });
  }

  rawCart = {};

  selectedCoupon = null;

  setSelectedCoupon(coupon) {
    this.selectedCoupon = coupon;
  }

  get couponMessage() {
    if (!this.selectedCoupon) return null;
    return this.selectedCoupon?.message;
  }

  get cart() {
    const { active, ...enrollments } = this.rawCart || {};
    const today = format(new Date(), "yyyy-MM-dd");
    const augmentedEnrollments = mapValues(enrollments, enrollment => {
      const course = CoursesStore?.coursesById?.[enrollment?.class?.courseId];
      return {
        ...enrollment,
        students: enrollment?.students?.slice()?.sort((a, b) => (a?.firstName > b?.firstName ? 1 : -1)),
        class: { ...(enrollment?.class || {}), course },
        isLate: enrollment?.class?.shippingDate && enrollment?.class?.shippingDate <= today,
        lateFee: MaterialsStore?.lateFeesByCourseId?.[course?.id] || 0
      };
    });
    return { active, ...augmentedEnrollments };
  }

  get activeEnrollmentId() {
    return this.cart.active;
  }

  get activeEnrollmentItem() {
    if (!this.cart.active) return null;
    return this.cart[this.cart.active];
  }

  get enrollmentsInCart() {
    const { active, ...enrollments } = this.cart || {};
    return Object.entries(enrollments)?.map(([id, enrollment]) => {
      const bundleTotals = enrollment?.bundles
        ? Object.values(enrollment?.bundles)?.map(
            b => b?.productList?.reduce((acc, next) => acc + (next?.price || 0), 0) || 0
          )
        : [0];
      const totalFromBundles = bundleTotals?.reduce((acc, next) => acc + next, 0);
      const totalFromProducts =
        enrollment?.products?.reduce((acc, next) => acc + (next?.price * next?.quantity || 0), 0) || 0;
      return { id, ...enrollment, total: totalFromBundles + totalFromProducts };
    });
  }

  get inProgressEnrollments() {
    return this.enrollmentsInCart?.filter(e => !e?.students?.length || !Object.keys(e?.bundles || {})?.length);
  }

  get enrollmentsReadyForCheckout() {
    return this.enrollmentsInCart?.filter(
      e => !!e?.teacher && !!e?.class && !!e?.students?.length && !!Object.keys(e?.bundles || {})?.length
    );
  }

  get shippingItems() {
    const enrollmentCourseIds = this.enrollmentsReadyForCheckout
      ?.filter(e => getProductsFromEnrollments([e])?.length > 0)
      ?.map(({ class: classObject }) => classObject?.courseId);
    const shippingItemsForEnrollments = enrollmentCourseIds?.map(cid => MaterialsStore?.shippingCostsByCourseId?.[cid]);
    const groupedShippingItems = groupBy(Object.values(shippingItemsForEnrollments), "id");

    return Object.values(groupedShippingItems)?.map(shippingItems => ({
      ...shippingItems?.[0],
      quantity: shippingItems?.length || 0
    }));
  }

  get arizonaSalesTaxItem() {
    const courseStates = this.enrollmentsReadyForCheckout
      ?.filter(e => getProductsFromEnrollments([e])?.length > 0)
      ?.filter(({ class: classObject }) => {
        const state = classObject?.location?.region?.toUpperCase();
        return state === "AZ" || state === "ARIZONA";
      });
    const { class: { courseId } = {} } = courseStates?.[0] || {};
    const hasArizonaCourse = !!courseId;
    if (hasArizonaCourse) {
      const taxItem = MaterialsStore?.arizonaSalesTaxByCourseId?.[courseId];
      return { ...taxItem, quantity: 1 };
    }
    return null;
  }

  get lateFeeItem() {
    const totalProducts = getProductsFromEnrollments(this.enrollmentsReadyForCheckout)?.length;
    if (!totalProducts) return null;

    const allLateFeeItems = this?.enrollmentsReadyForCheckout
      ?.filter(e => e?.isLate && getProductsFromEnrollments([e])?.length > 0)
      ?.map(e => e?.lateFee);
    const maxLateFeeItemPrice = Math.max(
      0,
      allLateFeeItems?.map(item => item?.price)
    );
    return allLateFeeItems?.find(item => item?.price === maxLateFeeItemPrice);
  }

  get AZTaxPercentage() {
    return (this?.arizonaSalesTaxItem?.price || 0) / 10000;
  }

  get shippingItemIds() {
    return this.shippingItems?.map(si => si?.id);
  }

  get productItemsReadyForCheckout() {
    return getProductsFromEnrollments(this.enrollmentsReadyForCheckout);
  }

  get productsTotal() {
    return this.enrollmentsReadyForCheckout?.reduce((acc, next) => {
      let totalAfterDiscount = next?.total - (next?.priceDiscount * 100 || 0);
      totalAfterDiscount = totalAfterDiscount < 0 ? 0 : totalAfterDiscount;
      return acc + totalAfterDiscount;
    }, 0);
  }

  get tax() {
    return this.AZTaxPercentage * this.productsTotal;
  }

  get shippingTotal() {
    return this.shippingItems?.map(({ price, quantity }) => price * quantity)?.reduce((acc, next) => acc + next, 0);
  }

  get lateFeeTotal() {
    return this.lateFeeItem?.price || 0;
  }

  get total() {
    return this.productsTotal + this.tax + this.shippingTotal + this.lateFeeTotal;
  }

  initialize() {
    try {
      const cartString = localStorage.getItem(STORAGE_KEY);
      this.rawCart = JSON.parse(cartString) || {};
    } catch {
      this.rawCart = {};
    }
  }

  addEnrollmentToCart(enrollmentItem) {
    const newEnrollmentId = ulid();
    this.rawCart = { ...this.rawCart, [newEnrollmentId]: enrollmentItem, active: newEnrollmentId };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(this.rawCart));
  }

  updateEnrollmentInCart(enrollmentItemId, updates) {
    this.rawCart = { ...this.rawCart, [enrollmentItemId]: { ...this.rawCart?.[enrollmentItemId], ...updates } };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(this.rawCart));
  }

  removeEnrollmentFromCart(enrollmentItemId, skipRedirect) {
    const { [enrollmentItemId]: _, ...enrollmentItemsToKeep } = this.rawCart || {};
    this.rawCart = enrollmentItemsToKeep;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(this.rawCart));
    if (enrollmentItemId === this.rawCart?.active) {
      this.setActiveEnrollmentId(null);
      if (!skipRedirect) navigate("/");
    }
  }

  setActiveEnrollmentId(enrollmentItemId) {
    this.rawCart.active = enrollmentItemId;
  }

  async getPromoCodeByName(promoCode) {
    try {
      const response = await request.get(`/promo/${promoCode}`);
      return response;
    } catch (err) {
      return null;
    }
  }

  async applyCoupon(couponCode) {
    couponCode = couponCode.toUpperCase();

    let coupon = await this.getPromoCodeByName(couponCode);

    // VERIFY THE CONDITIONS OF THE COUPON AND RETURN A DESCRIPTIVE MESSAGE / STATUS

    if (coupon) {
      if (coupon?.type === "Percent Off") {
        coupon.percentage = coupon?.value < 1 ? numeral(coupon?.value).multiply(100).value() : coupon?.value;
      } else {
        coupon.amount = coupon?.value / 100;
      }

      if (coupon?.status === "Active") {
        if (coupon.amount > 0) {
          this.setSelectedCoupon({
            success: true,
            message: `Coupon code applied! This coupon gives you $${coupon.amount} off.`,
            id: coupon.id
          });

          const { active, ...enrollments } = this.cart || {};

          this.rawCart = {
            active,
            ...mapValues(enrollments, enrollment => {
              enrollment.priceDiscount = coupon.amount;

              // remove the key totalAfterDiscount from the products and bundles
              enrollment.bundles = Object.values(enrollment.bundles)?.map(b => {
                // remove the key totalAfterDiscount from the products
                b.productList = b.productList?.map(p => {
                  delete p.totalAfterDiscount;
                  return p;
                });

                delete b.totalAfterDiscount;
                return b;
              });

              enrollment.products = enrollment.products?.map(p => {
                delete p.totalAfterDiscount;
                return p;
              });

              return enrollment;
            })
          };
        } else if (coupon.percentage > 0) {
          this.setSelectedCoupon({
            success: true,
            message: `Coupon code applied! This coupon gives you ${coupon.percentage}% off.`,
            id: coupon.id
          });

          const { active, ...enrollments } = this.cart || {};

          this.rawCart = {
            active,
            ...mapValues(enrollments, enrollment => {
              enrollment.priceDiscount = 0; //how much the user saved with the coupon

              enrollment.bundles = Object.values(enrollment.bundles)?.map(b => {
                const total = b?.productList?.reduce((acc, next) => acc + (next?.price || 0), 0) || 0;
                const totalAfterDiscount = total - (total * coupon.percentage) / 100;
                enrollment.priceDiscount += (total - totalAfterDiscount) / 100;
                return { ...b, totalAfterDiscount };
              });

              enrollment.products = enrollment.products?.map(p => {
                const total = p?.price * p?.quantity || 0;
                const totalAfterDiscount = total - (total * coupon.percentage) / 100;
                enrollment.priceDiscount += (total - totalAfterDiscount) / 100;
                return { ...p, totalAfterDiscount };
              });

              return enrollment;
            })
          };
        }
      } else {
        this.setSelectedCoupon({
          success: false,
          message: `Coupon code is not active.`
        });
        // remove the key totalAfterDiscount if it exists
        const { active, ...enrollments } = this.cart || {};

        this.rawCart = {
          active,
          ...mapValues(enrollments, enrollment => {
            delete enrollment.priceDiscount;
            // remove the key totalAfterDiscount from the products and bundles
            enrollment.bundles = Object.values(enrollment.bundles)?.map(b => {
              // remove the key totalAfterDiscount from the products
              b.productList = b.productList?.map(p => {
                delete p.totalAfterDiscount;
                return p;
              });

              delete b.totalAfterDiscount;
              return b;
            });

            enrollment.products = enrollment.products?.map(p => {
              delete p.totalAfterDiscount;

              return p;
            });

            return enrollment;
          })
        };
      }
    } else {
      this.setSelectedCoupon({
        success: false,
        message: `Coupon code is invalid.`
      });

      const { active, ...enrollments } = this.cart || {};

      this.rawCart = {
        active,
        ...mapValues(enrollments, enrollment => {
          delete enrollment.priceDiscount;

          // remove the key totalAfterDiscount from the products and bundles
          enrollment.bundles = Object.values(enrollment.bundles)?.map(b => {
            // remove the key totalAfterDiscount from the products
            b.productList = b.productList?.map(p => {
              delete p.totalAfterDiscount;
              return p;
            });
            delete b.totalAfterDiscount;
            return b;
          });

          enrollment.products = enrollment.products?.map(p => {
            delete p.totalAfterDiscount;
            return p;
          });

          return enrollment;
        })
      };
    }
  }

  async checkOut(card) {
    try {
      const { id, infusionsoftId } = AuthStore || {};
      const registrations = this.enrollmentsReadyForCheckout?.map(r => {
        const parent = { id, infusionsoftId };
        const students = r?.students?.map(student => ({ id: student?.id, infusionsoftId: student?.infusionsoftId }));
        const teacher = { id: r?.teacher?.id, infusionsoftId: r?.teacher?.infusionsoftId };
        const classObject = { id: r?.class?.id || r?.class?.classId, courseId: r?.class?.courseId };

        const simplifiedProducts =
          r?.products?.map(({ id, quantity }) => ({
            id,
            quantity
          })) || [];

        const simplifiedProductsFromBundles =
          Object.values(r?.bundles)
            ?.map(b =>
              b?.productList?.map(({ id }) => ({
                id,
                quantity: 1
              }))
            )
            ?.flat() || [];

        const shippingItemForCourse = MaterialsStore?.shippingCostsByCourseId?.[classObject?.courseId];
        const totalProducts = getProductsFromEnrollments(this.enrollmentsReadyForCheckout)?.length;
        const simplifiedShippingProduct = totalProducts > 0 ? { id: shippingItemForCourse?.id, quantity: 1 } : null;

        const simplifiedLateFeeProduct = this.lateFeeItem?.id ? { id: this.lateFeeItem?.id, quantity: 1 } : null;

        const simplifiedAZTaxProduct = this.arizonaSalesTaxItem?.id
          ? { id: this.arizonaSalesTaxItem?.id, price: Math.round(r?.total * this.AZTaxPercentage), quantity: 1 }
          : null;

        const combinedProductQuantitiesById = simplifiedProducts
          ?.concat(simplifiedProductsFromBundles)
          ?.concat(simplifiedShippingProduct)
          ?.concat(simplifiedLateFeeProduct)
          ?.filter(Boolean)
          ?.reduce((acc, next) => {
            acc[next?.id] = (acc[next?.id] || 0) + next?.quantity;
            return acc;
          }, {});

        const products = Object.entries(combinedProductQuantitiesById)
          ?.map(([id, quantity]) => ({ id, quantity }))
          ?.concat(simplifiedAZTaxProduct)
          ?.filter(Boolean);

        const noProductsToShip = products?.filter(({ id }) => !this.shippingItemIds?.includes(id))?.length === 0;
        return {
          teacher,
          class: classObject,
          students,
          parent,
          products,
          noProductsToShip,
          promoCodeId: this.selectedCoupon?.success ? this.selectedCoupon?.id : null
        };
      });
      await request.post(`/registrations`, { body: { registrations, card: { id: card?.id } } });
      try {
        ReactPixel.track("CompleteRegistration", { value: Number((this.total / 100).toFixed(2)), currency: "USD" });
      } catch {}
      const thankYouParams = getThankYouParamsForEnrollments(this.enrollmentsReadyForCheckout);
      return { success: true, thankYouParams };
    } catch (err) {
      let error;
      const { message } = err?.response?.data || {};
      if (message === "Error processing payment") {
        error =
          "Looks like there was an issue processing your card. Check your inputs and try again, or use another card.";
      } else {
        error =
          "Looks like something went wrong. If this keeps happening, please contact us at support@letsplaymusicsite.com";
      }

      return { success: false, error };
    }
  }

  clearEnrollmentsAfterCheckOut() {
    this.enrollmentsReadyForCheckout?.map(({ id }) => this.removeEnrollmentFromCart(id, true));
  }

  clear() {
    Object.keys(this.cart).forEach(enrollmentId => this.removeEnrollmentFromCart(enrollmentId));
  }
}

export default new CartStore();
