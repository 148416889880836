import React from "react";
import { observer } from "mobx-react";
import { Button } from "../../-common";
import "./TeacherBioModal.scss";

function TeacherBioModal({ teacher, close }) {
  const paragraphs = teacher?.bio?.split(/\r?\n/)?.map((text, i) => (
    <div className="paragraph" key={i}>
      {text === "" ? <div className="empty-line" /> : text}
    </div>
  ));
  return (
    <div className="teacher-bio-modal">
      <div className="content">
        <div className="title">
          {teacher?.firstName} {teacher?.lastName}'s Bio
        </div>
        <div className="bio-text">{paragraphs}</div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons" />
        <div className="right-buttons">
          <Button type="primary" action={close}>
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(TeacherBioModal);
